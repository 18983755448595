import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './index.css';
import _ from 'lodash'

window.generateAvatarLink = function (character, linkType) {
  let itemEntries = window.getCharacterItemEntries(character)

  let backgroundColor = JSON.parse(localStorage['backgroundColor'] || false) || {"hsl":{"h":0,"s":0,"l":0,"a":0},"hex":"transparent","rgb":{"r":0,"g":0,"b":0,"a":0},"hsv":{"h":0,"s":0,"v":0,"a":0},"oldHue":0,"source":"rgb"}
  const bgColorText = `${backgroundColor.rgb.r},${backgroundColor.rgb.g},${backgroundColor.rgb.b},${backgroundColor.rgb.a}`

  let itemEntriesPayload = JSON.stringify(itemEntries)
  itemEntriesPayload = encodeURIComponent(itemEntriesPayload.substr(1, itemEntriesPayload.length - 2))

  let {
    animating,
    action,
    frame,
    mercEars,
    illiumEars,
    highFloraEars,
    zoom,
    name,
    flipX,
    includeBackground
  } = character

  return `https://maplestory.io/api/character/${itemEntriesPayload}/${linkType ? linkType : (`${action}/${animating ? 'animated' : frame}`)}?showears=${mercEars}&showLefEars=${illiumEars}&showHighLefEars=${highFloraEars}&resize=${zoom}&name=${encodeURI(name || '')}&flipX=${flipX}` + (includeBackground ? `&bgColor=${bgColorText}` : '')
}

window.getCharacterItemEntries = function (character) {
  return Object.values(character.selectedItems).filter(item => item.id && (item.visible === undefined || item.visible)).map(item => {
    let itemEntry = {
      itemId: Number(item.id)
    }

    if ((item.id >= 20000 && item.id < 30000) || (item.id >= 1010000 && item.id < 1020000)) itemEntry.animationName = character.emotion
    if (item.region && item.region.toLowerCase() !== 'gms') itemEntry.region = item.region
    if (item.version && item.version.toLowerCase() !== 'latest') itemEntry.version = item.version
    if (item.hue) itemEntry.hue = item.hue
    if (item.saturation !== 1) itemEntry.saturation = item.saturation
    if (item.contrast !== 1) itemEntry.contrast = item.contrast
    if (item.brightness !== 1) itemEntry.brightness = item.brightness
    if (item.alpha !== 1) itemEntry.alpha = item.alpha
    if (item.islot) itemEntry.islot = item.islot
    if (item.vslot) itemEntry.vslot = item.vslot
    if (item.equipFrame) itemEntry.equipFrame = item.equipFrame
    if (item.disableEffect) itemEntry.disableEffect = item.disableEffect
    if (item.glow) itemEntry.glow = item.glow
    if (item.grayscale) itemEntry.grayscale = item.grayscale
    if (item.invert) itemEntry.invert = item.invert
    if (item.oilPaint) itemEntry.oilPaint = item.oilPaint
    if (item.sepia) itemEntry.sepia = item.sepia

    return itemEntry
  })
}

document.wzPromise = fetch(`https://maplestory.io/api/wz?cache=${Math.floor(Date.now() / (1000*60*60))}`).then(res => res.json())
.then(response => {
  let WZs = response.filter(wzEntry => wzEntry.isReady).map(wzEntry => {
    return {
      ...wzEntry,
      region: wzEntry.region
    }
  })
  let versions = _.groupBy(WZs, 'region')
  document.mapleVersions = versions

  let region = localStorage['region'], version = localStorage['version']

  if (!region || (_.findIndex(versions[region], ver => ver.mapleVersionId === version) === -1)) {
    if (!versions[region])
      localStorage['region'] = 'GMS'
    var possibleVersions = versions[localStorage['region']]
    if (possibleVersions && possibleVersions.length > 0)
    {
      const newVersion = (possibleVersions[possibleVersions.length - 1]).mapleVersionId;
      console.warn('Updating to ', newVersion)
      localStorage['version'] = newVersion
    }
  }

  return versions;
}).then(versions => {
  if (localStorage['initialized'] !== '2' || localStorage['version'] === 'latest') {
    if (!versions[localStorage['region']])
      localStorage['region'] = 'GMS'
    var possibleVersions = versions[localStorage['region']]
    if (possibleVersions && possibleVersions.length > 0)
      localStorage['version'] = (possibleVersions[possibleVersions.length - 1]).mapleVersionId
    localStorage['initialized'] = '2'

    window.location.reload()
  }

  if (!localStorage['language']) {
    if (navigator.language.startsWith('ko')) localStorage['language'] = 'kr'
    else if (navigator.language.startsWith('ja')) localStorage['language'] = 'jp'
    else if (navigator.language.startsWith('zh')) localStorage['language'] = 'ch'
    else if (navigator.language.startsWith('nl')) localStorage['language'] = 'nl'
    else if (navigator.language.startsWith('pt')) localStorage['language'] = 'br'
    else localStorage['language'] = 'en'
  }

  ReactDOM.render(<App />, document.getElementById('root'));
})

// const isOnDev = window.location.host.indexOf('.dev') !== -1
// const isOnDomain = window.location.host.indexOf('s.im') !== -1
// const isOnHttps = window.location.protocol.indexOf('https') !== -1
// if (isOnDomain && !isOnHttps)
//   window.location.protocol = 'https'
// else {
  // if (!isOnDev) registerServiceWorker();
  // unregister()
// }
