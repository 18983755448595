import React, { Component } from 'react'
import './index.css'
import { DraggableCore } from 'react-draggable'
import _ from 'lodash'

class CharacterCanvasElement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tryCount: 0
    }

    this.updateCharacterDetails(props, true)
  }

  componentDidUpdate(prevProps) {
    const ifChanged = ['skin', 'selectedItems', 'action', 'frame', 'mercEars', 'illiumEars', 'zoom', 'flipX']
    if (_.find(ifChanged, (property) => this.props.character[property] !== prevProps.character[property])){
      this.updateCharacterDetails(this.props, false)
    }
  }

  updateCharacterDetails(props, isSync) {
    const { character } = props
    const link = window.generateAvatarLink(character)

    if (isSync) {
      this.state.linkUsed = link
      fetch(link).then(res => {
        if (!res.ok) 
          throw new Error('Response from API is not OK, something went wrong')
        if (this.state.linkUsed === link) {
          this.setState({
            details: res
          })
        }
      }, this.showError.bind(this))
    } else this.setState({ linkUsed: link }, () => {
      fetch(link).then(res => {
        if (!res.ok) 
          throw new Error('Response from API is not OK, something went wrong')
        if (this.state.linkUsed === link) {
          this.setState({
            details: res
          })
        }
      }, this.showError.bind(this))
    })
  }

  render() {
    const { character, onUpdateRenderablePosition, onStart, onStop, onClick, selected } = this.props
    const { details } = this.state
    const styling = {
      transform: `translate(${character.position.x}px, ${character.position.y}px) translate(${details ? -0 : 0}px, ${details ? -0 : 0}px)`
    }

    const imgStyle = {
      position: 'relative',
      touchAction: 'none'
    }

    const link = window.generateAvatarLink(character)

    return (
      <DraggableCore
        onStart={onStart}
        onStop={onStop}
        onDrag={onUpdateRenderablePosition}
        position={character.position}
        >
        <div className={selected ? 'selected-canvas-element' : ''} style={styling}>
         {
            details ? (<img
              src={link}
              alt=''
              className='renderable-instance'
              draggable={false}
              onClick={onClick}
              onError={this.showError.bind(this)}
              style={imgStyle}
              />) : <div className='loading-character'>&nbsp;</div>
         }
        </div>
        </DraggableCore>
    )
  }

  showError() {
    setTimeout(function () {
      if (this.state.tryCount < 10) {
        setTimeout(function () {
          this.setState({ tryCount: this.state.tryCount + 1 }, () => {
            this.updateCharacterDetails(this.props)
          })
        }.bind(this), 1500)
      }
    }.bind(this), 3500)
  }
}

export default CharacterCanvasElement
