import React, { Component } from 'react'
import './index.css'
import _ from 'lodash'
import Toggle from 'react-toggle'
import { Tooltip } from 'react-tippy'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import 'react-tippy/dist/tippy.css';
import RcTooltip from 'rc-tooltip'
import Slider from 'rc-slider'

const mobLoader = fetch(`https://maplestory.io/api/${localStorage['region']}/${localStorage['version']}/mob`).then(res => res.json())
  .then(mobResp => {
    let uniqMobs = _.uniqBy(mobResp, mob => mob.name)
    mobs = uniqMobs.map(mob => {
      return {
        mobId: mob.id,
        name: mob.name
      }
    })
  }) // For some reason the standard [].map doesn't work here. :(

let mobs = []

class MobEntry extends Component {
  constructor(props) {
    super(props)

    this.state = {};

    if (mobs.length > 0) this.state.mobsLoaded = true;
    else mobLoader.then(() => this.setState({ mobsLoaded: true }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mob && this.props.mob && prevProps.mob.mobId === this.props.mob.mobId) return
    const { mob: { mobId } } = this.props
  }

  render() {
    const { mob, isSelected, canvasMode, onUpdateMob, onDeleteMob, ...otherProps } = this.props
    var resizeIndex = mob.summary.indexOf("?resize=");
    var zoomlessMobUrl = resizeIndex > 0 ? mob.summary.substring(0, resizeIndex) : mob.summary;
    return (
      <Tooltip html={this.customizeMob(mob)} delay={[100, 300]} position={canvasMode ? undefined : 'bottom'} interactive={true} theme='light' distance={375} arrow={true}>
        <div
          className={'mob ' + (mob.visible ? 'disabled ' : 'enabled ') + (isSelected ? 'active' : 'inactive')}
          style={{
            backgroundImage: 'url(' + zoomlessMobUrl + ')',
            transform: mob.flipX ? 'scaleX(-1)' : ''
          }}
          {...otherProps}>&nbsp;</div>
      </Tooltip>
    )
  }

  customizeMob(mob) {
    const { localized } = this.props
    return (<div className='mob-customizeable-options'>
      <div>
        <a href="#" className='btn bg-red text-white right' onClick={this.deleteMob.bind(this)}>{localized.deleteMob}</a>
      </div>
      <label>
        <span>{localized.mobType}</span>
        <select onChange={this.changeMobId.bind(this)} value={mob.mobId}>
          {
            mobs.map(a => (
              <option value={a.mobId} key={'mobSelect' + a.mobId}>{a.name}</option>
            ))
          }
        </select>
      </label>
      <label>
        <span>Animation</span>
        <select onChange={this.changeMobAnimation.bind(this)} value={mob.animation}>
          <option value='stand'>Stand</option>
          <option value='fly'>Fly</option>
        </select>
      </label>
      <label>
        <span>{localized.frame}</span>
        <Slider
          value={mob.frame || 0}
          min={0}
          max={10}
          handle={handle}
          onChange={this.changeFrame.bind(this)} />
      </label>
      <label>
        <span>{localized.zoom}</span>
        <Slider
          value={mob.zoom || 1}
          min={1}
          max={10}
          handle={handle}
          onChange={this.changeZoom.bind(this)} />
      </label>
      <label>
        <span>{localized.visible}</span>
        <Toggle onChange={this.toggleVisibility.bind(this)} checked={mob.visible} />
      </label>
      <label>
        <span>{localized.flipHorizontal}</span>
        <Toggle onChange={this.toggleFlipX.bind(this)} checked={mob.flipX} />
      </label>
      <label>
        <span>{localized.footholdSnapping}</span>
        <Toggle onChange={this.toggleFHSnap.bind(this)} checked={mob.fhSnap || false} />
      </label>
      <label>
        <span>{localized.lock}</span>
        <Toggle onChange={this.toggleLock.bind(this)} checked={mob.locked} />
      </label>
      <br />
      <a href={`https://maplestory.io/api/${localStorage['region']}/${localStorage['version']}/mob/${mob.mobId}/download`} target='_blank' rel="noopener noreferrer">
        <div className='download-bar bg-purple'>
          <div className='equipped-items-item-meta'>
            <div className='equipped-items-item-meta-name text-white'>{localized.downloadSpriteSheet}</div>
            <div className='equipped-items-item-meta-category text-white'>({localized.willDownloadZip})</div>
          </div>
        </div>
      </a>
    </div>)
  }

  toggleFHSnap(e) {
    this.props.onUpdateMob(this.props.mob, { fhSnap: !this.props.mob.fhSnap })
  }

  toggleFlipX(e) {
    this.props.onUpdateMob(this.props.mob, { flipX: !this.props.mob.flipX })
  }

  deleteMob() {
    this.props.onDeleteMob(this.props.mob)
  }

  changeMobId(e) {
    this.props.onUpdateMob(this.props.mob, { mobId: e.target.value })
  }

  changeMobAnimation(e) {
    this.props.onUpdateMob(this.props.mob, { animation: e.target.value })
  }

  changeFrame(e) {
    this.props.onUpdateMob(this.props.mob, { frame: e });
  }

  changeZoom(e) {
    this.props.onUpdateMob(this.props.mob, { zoom: e });
  }

  toggleVisibility(e) {
    this.props.onUpdateMob(this.props.mob, { visible: !this.props.mob.visible })
  }

  toggleLock(e) {
    this.props.onUpdateMob(this.props.mob, { locked: !this.props.mob.locked })
  }
}

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <RcTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      style={{ border: "solid 2px hsl(" + value + ", 53%, 53%)" }}
      key={index}
    >
      <Handle value={value} {...restProps} />
    </RcTooltip>
  );
};

export default MobEntry
