const all = {
  en: {
    maplestory: 'MapleStory',
    simulator: 'Simulator',
    understand: 'I understand',
    isCashFilter: 'Cash',
    genderFilter: 'Gender',
    male: 'Male',
    female: 'Female',
    quickView: 'Quick View',
    removeAll: 'Remove All',
    alpha: 'Public Alpha',
    unofficialNotice: 'The unofficial MapleStory simulator and designer.',
    properties: 'Properties',
    expression: 'Facial Expression',
    action: 'Pose / Action',
    skin: 'Skin',
    zoom: 'Zoom',
    universal: 'Universal',
    pet: 'Pet',
    character: 'Character',
    name: 'Name',
    illiumEars: 'Flora Ears',
    highFloraEars: 'High Flora Ears',
    mercEars: 'Mercedes Ears',
    frame: 'Frame',
    animate: 'Animate',
    addBgToGif: 'Add BG to GIF',
    visible: 'Visible',
    flipHorizontal: 'Flip Horizontal',
    footholdSnapping: 'Foothold Snapping',
    lock: 'Lock',
    downloadSpriteSheet: 'Download Spritesheet',
    willDownloadZip: 'Will download a .zip',
    downloadLayeredSpriteSheet: 'Download Layered Spritesheet',
    requiresPDNPlugin: 'Requires PDN Plugin',
    deleteCharacter: 'Delete Character',
    deleteCharacterConfirm: 'Are you sure you wish to delete this character?',
    petType: 'Pet',
    npcType: 'NPC',
    mobType: 'Mob',
    deletePet: 'Delete Pet',
    deleteNpc: 'Delete Npc',
    deleteMob: 'Delete Mob',
    accessory: "Accessory",
    badge: "Badge",
    belt: "Belt",
    earrings: "Earrings",
    emblem: "Emblem",
    faceAccessory: "Face Accessory",
    medal: "Medal",
    eyeDecoration: "Eye Decoration",
    earring: "Earring",
    ring: "Ring",
    pendant: "Pendant",
    pocketItem: "Pocket Item",
    powerSource: "Power Source",
    shoulderAccessory: "Shoulder Accessory",
    totem: "Totem",
    armor: "Armor",
    hat: "Hat",
    cape: "Cape",
    top: "Top",
    glove: "Glove",
    overall: "Overall",
    bottom: "Bottom",
    shield: "Shield",
    shoes: "Shoes",
    testArmor: "Test Armor",
    other: "Other",
    android: "Android",
    dragonEquipment: "Dragon Equipment",
    mechanicalHeart: "Mechanical Heart",
    mechanicEquipment: "Mechanic Equipment",
    petEquipment: "Pet Equipment",
    bits: "Bits",
    shovel: "Shovel",
    pickaxe: "Pickaxe",
    skillEffect: "Skill Effect",
    petUse: "Pet Use",
    oneHandedWeapon: "One-Handed Weapon",
    scepter: "Scepter",
    oneHandedAxe: "One-Handed Axe",
    katara: "Katara",
    cane: "Cane",
    dagger: "Dagger",
    desperado: "Desperado",
    whipBlade: "Whip Blade",
    oneHandedBluntWeapon: "One-Handed Blunt Weapon",
    shiningRod: "Shining Rod",
    soulShooter: "Soul Shooter",
    staff: "Staff",
    oneHandedSword: "One-Handed Sword",
    wand: "Wand",
    testWeapon: "Test Weapon",
    cash: "Cash",
    psyLimiter: "Psy-limiter",
    chain: "Chain",
    gauntlet: "Gauntlet",
    secondaryWeapon: "Secondary Weapon",
    ballast: "Ballast",
    magicMarble: "Magic Marble",
    spellbook: "Spellbook",
    arrowFletching: "Arrow Fletching",
    powderKeg: "Powder Keg",
    farSight: "Far Sight",
    card: "Card",
    ironChain: "Iron Chain",
    bowThimble: "Bow Thimble",
    jewel: "Jewel",
    document: "Document",
    magicArrow: "Magic Arrow",
    charm: "Charm",
    orb: "Orb",
    rosary: "Rosary",
    daggerScabbard: "Dagger Scabbard",
    wristBand: "Wrist Band",
    arrowhead: "Arrowhead",
    jettSCore: "Jett's Core",
    novaSEssence: "Nova's Essence",
    soulRing: "Soul Ring",
    magnum: "Magnum",
    kodachi: "Kodachi",
    whistle: "Whistle",
    jettFists: "Jett Fists",
    mass: "Mass",
    foxMarble: "Fox Marble",
    coreController: "Core Controller",
    charges: "Charges",
    chessPiece: "Chess Piece",
    transmitter: "Transmitter",
    wings: "Wings",
    twoHandedWeapon: "Two-Handed Weapon",
    twoHandedAxe: "Two-Handed Axe",
    bow: "Bow",
    crossbow: "Crossbow",
    dualBowgun: "Dual Bowgun",
    gun: "Gun",
    handCannon: "Hand Cannon",
    knuckle: "Knuckle",
    twoHandedBlunt: "Two-Handed Blunt",
    poleArm: "Pole Arm",
    spear: "Spear",
    twoHandedSword: "Two-Handed Sword",
    claw: "Claw",
    katana: "Katana",
    fan: "Fan",
    lapis: "Lapis",
    lazuli: "Lazuli",
    armCannon: "Arm Cannon",
    character: "Character",
    face: "Face",
    hair: "Hair",
    monster: "Monster",
    crusaderCodex: "Crusader Codex",
    monsterBattle: "Monster Battle",
    mount: "Mount",
    consumable: "Consumable",
    potion: "Potion",
    foodAndDrink: "Food and Drink",
    statusCure: "Status Cure",
    petFood: "Pet Food",
    transformation: "Transformation",
    eXPPotion: "EXP Potion",
    characterModification: "Character Modification",
    timeSaver: "Time Saver",
    equipmentBox: "Equipment Box",
    teleportItem: "Teleport Item",
    eXPBuff: "EXP Buff",
    appearance: "Appearance",
    armorScroll: "Armor Scroll",
    helmet: "Helmet",
    eye: "Eye",
    topwear: "Topwear",
    bottomwear: "Bottomwear",
    gloves: "Gloves",
    belts: "Belts",
    shoulder: "Shoulder",
    equipment: "Equipment",
    pet: "Pet",
    weaponScroll: "Weapon Scroll",
    oneHandedBW: "One-Handed BW",
    twoHandedBW: "Two-Handed BW",
    polearm: "Polearm",
    zero: "Zero",
    specialScroll: "Special Scroll",
    cleanSlateScroll: "Clean Slate Scroll",
    slotCarver: "Slot Carver",
    bonusPotentialScroll: "Bonus Potential Scroll",
    chaosScroll: "Chaos Scroll",
    equipEnhancement: "Equip Enhancement",
    potentialScroll: "Potential Scroll",
    awakeningStamp: "Awakening Stamp",
    innocenceScroll: "Innocence Scroll",
    fusionAnvil: "Fusion Anvil",
    whiteScroll: "White Scroll",
    hammer: "Hammer",
    magnifyingGlass: "Magnifying Glass",
    timeExtension: "Time Extension",
    luckyDayScroll: "Lucky Day Scroll",
    protectionScroll: "Protection Scroll",
    safetyScroll: "Safety Scroll",
    guardianScroll: "Guardian Scroll",
    equipmentChange: "Equipment Change",
    soulWeapon: "Soul Weapon",
    miracleCube: "Miracle Cube",
    trade: "Trade",
    masteryBook: "Mastery Book",
    aPSPReset: "AP/SP Reset",
    monsterTaming: "Monster Taming",
    circulator: "Circulator",
    dust: "Dust",
    tablet: "Tablet",
    projectile: "Projectile",
    arrow: "Arrow",
    thrown: "Thrown",
    crossbowBolt: "Crossbow Bolt",
    bullet: "Bullet",
    monsterFamiliar: "Monster/Familiar",
    monsterCard: "Monster Card",
    familiarSkill: "Familiar Skill",
    familiarCard: "Familiar Card",
    recipe: "Recipe",
    smithingRecipe: "Smithing Recipe",
    accessoryCraftingRecipe: "Accessory Crafting Recipe",
    alchemyRecipe: "Alchemy Recipe",
    synergyMachine: "Synergy Machine",
    megaphone: "Megaphone",
    summoningSack: "Summoning Sack",
    message: "Message",
    lieDetector: "Lie Detector",
    wedding: "Wedding",
    owl: "Owl",
    capsule: "Capsule",
    itemPot: "Item Pot",
    monsterDrop: "Monster Drop",
    questItem: "Quest Item",
    cosmetic: "Cosmetic",
    minigame: "Minigame",
    petCommand: "Pet Command",
    book: "Book",
    effect: "Effect",
    coin: "Coin",
    container: "Container",
    eXPTicket: "EXP Ticket",
    eventItem: "Event Item",
    cashShop: "Cash Shop",
    rewardItem: "Reward Item",
    potentialLock: "Potential Lock",
    fusion: "Fusion",
    coupon: "Coupon",
    crafting: "Crafting",
    mineralOre: "Mineral Ore",
    mineralProcessed: "Mineral Processed",
    rareOre: "Rare Ore",
    rareProcessedOre: "Rare Processed Ore",
    craftingItem: "Crafting Item",
    herb: "Herb",
    herbOil: "Herb Oil",
    maker: "Maker",
    chair: "Chair",
    extractor: "Extractor",
    title: "Title",
    decoration: "Decoration",
    key: "Key",
    commerci: "Commerci",
    tradeGood: "Trade Good",
    shipEnhancement: "Ship Enhancement",
    evolutionLab: "Evolution Lab",
    core: "Core",
    mission: "Mission",
    nebulite: "Nebulite",
    rankDNebulite: "Rank D Nebulite",
    rankCNebulite: "Rank C Nebulite",
    rankBNebulite: "Rank B Nebulite",
    rankANebulite: "Rank A Nebulite",
    rankSNebulite: "Rank S Nebulite",
    teleportRock: "Teleport Rock",
    package: "Package",
    itemStore: "Item Store",
    questHelper: "Quest Helper",
    randomReward: "Random Reward",
    specialItem: "Special Item",
    gachapon: "Gachapon",
    surpriseBox: "Surprise Box",
    exchangeCoupon: "Exchange Coupon",
    equipmentModification: "Equipment Modification",
    scroll: "Scroll",
    upgradeSlot: "Upgrade Slot",
    sPAPModification: "SP/AP Modification",
    protection: "Protection",
    eXPCoupon: "EXP Coupon",
    dropCoupon: "Drop Coupon",
    entryPass: "Entry Pass",
    inventorySlot: "Inventory Slot",
    eXPCoupons: "EXP Coupons",
    skillModification: "Skill Modification",
    weapon: "Weapon",
    hairCoupon: "Hair Coupon",
    hairColorCoupon: "Hair Color Coupon",
    faceCoupon: "Face Coupon",
    cosmeticLens: "Cosmetic Lens",
    skinCoupon: "Skin Coupon",
    ear: "Ear",
    facialExpression: "Facial Expression",
    special: "Special",
    petAppearance: "Pet Appearance",
    freeMarket: "Free Market",
    hiredMerchant: "Hired Merchant",
    storePermit: "Store Permit",
    auctionHouse: "Auction House",
    messengerAndSocial: "Messenger and Social",
    weatherEffect: "Weather Effect",
    messenger: "Messenger",
    kite: "Kite",
    note: "Note",
    song: "Song",
    characterEffect: "Character Effect",
    guildForumEmoticon: "Guild Forum Emoticon",
    messageboard: "Messageboard",
    miscellaneous: "Miscellaneous",
    cakeVsPie: "Cake vs Pie",
    language: 'Language',
    playMusic: 'Play Music',
    region: 'Region',
    version: 'Version',
    clone: 'Clone',
    downloadMinimalSpriteSheet: 'Download Minimal Spritesheet',
    downloadMinimalLayeredSpriteSheet: 'Download Minimal Layered Spritesheet',
    export: 'Export JSON',
    importCharacter: 'Import Character',
    npc: 'NPC',
    mob: 'Mob',
    reportIssues: 'Report issues'
  },
  br: {
    maplestory: 'MapleStory',
    understand: 'Eu Concordo',
    isCashFilter: 'Apenas Cash',
    genderFilter: 'Filtro de Gênero',
    male: 'Masculino',
    female: 'Feminino',
    quickView: 'Visualização Rápida',
    removeAll: 'Remover Todos',
    alpha: 'Alpha Público',
    unofficialNotice: 'O simulador e designer não-oficial de Maplestory.',
    properties: 'Propriedades',
    expression: 'Expressão Facial',
    action: 'Pose / Ação',
    skin: 'Pele',
    zoom: 'Zoom',
    universal: 'Universal',
    pet: 'Mascote',
    character: 'Personagem',
    name: 'Nome',
    illiumEars: 'Orelhas de Illium',
    mercEars: 'Orelhas de Mercedes',
    frame: 'Quadro',
    animate: 'Animar',
    addBgToGif: 'Adicionar fundo ao GIF',
    visible: 'Visível',
    flipHorizontal: 'Virar Horizontalmente',
    footholdSnapping: 'Apoiar pés automáticamente',
    lock: 'Trancar',
    downloadSpriteSheet: 'Baixar Spritesheet',
    willDownloadZip: 'Será baixado um .zip',
    downloadLayeredSpriteSheet: 'Baixar Spritesheet dividida por Camadas',
    requiresPDNPlugin: 'Requer  do Plugin PDN',
    deleteCharacter: 'Deletar Personagem',
    petType: 'Tipo de Mascote',
    deletePet: 'Deletar Mascote',
    accessory: "Acessório",
    badge: "Insígnia",
    belt: "Cinto",
    earrings: "Brincos",
    emblem: "Emblema",
    faceAccessory: "Accessório de Face",
    medal: "Medalha",
    eyeDecoration: "Decoração de Olho",
    earring: "Brinco",
    ring: "Anel",
    pendant: "Pingente",
    pocketItem: "Item de Bolso",
    powerSource: "Fonte de Energia",
    shoulderAccessory: "Ombreira",
    totem: "Totem",
    armor: "Armadura",
    hat: "Chapéu",
    cape: "Capa",
    top: "Camisa",
    glove: "Luva",
    overall: "Macacão",
    bottom: "Calça",
    shield: "Escudo",
    shoes: "Sapatos",
    testArmor: "Armadura de Teste",
    other: "Outro",
    android: "Androide",
    dragonEquipment: "Equipamento de Dragão",
    mechanicalHeart: "Coração Mecânico",
    mechanicEquipment: "Equipamento de Mecânico",
    petEquipment: "Equipmento de Mascote",
    bits: "Bits",
    shovel: "Pá",
    pickaxe: "Picareta",
    skillEffect: "Efeito de Habilidade",
    petUse: "Item de Mascote",
    oneHandedWeapon: "Arma de uma Mão",
    scepter: "Cetro",
    oneHandedAxe: "Machado de uma Mão",
    katara: "Katara",
    cane: "Bengala",
    dagger: "Adaga",
    desperado: "Desperado",
    whipBlade: "Espada-Chicote",
    oneHandedBluntWeapon: "Porrete de uma Mão",
    shiningRod: "Cajado Brilhante",
    soulShooter: "Soul Shooter",
    staff: "Bastão",
    oneHandedSword: "Espada de uma Mão",
    wand: "Varinha",
    testWeapon: "Arma de Teste",
    cash: "Cash",
    psyLimiter: "Limitador Psíquico",
    chain: "Corrente",
    gauntlet: "Manopla",
    secondaryWeapon: "Arma Secundária",
    ballast: "Lastro",
    magicMarble: "Berlinde Mágico",
    spellbook: "Livro de Feitiços",
    arrowFletching: "Penas de Flecha",
    powderKeg: "Barril de Pó",
    farSight: "Visão Distante",
    card: "Carta",
    ironChain: "Corrente de Ferro",
    bowThimble: "Luvas para Arco",
    jewel: "Jóia",
    document: "Documento",
    magicArrow: "Flecha Mágica",
    charm: "Amuleto",
    orb: "Orbe",
    rosary: "Rosário",
    daggerScabbard: "Bainha de Adaga",
    wristBand: "Pulseira",
    arrowhead: "Ponta de Flecha",
    jettSCore: "Núcleo de Jett",
    novaSEssence: "Essência dos Nova",
    soulRing: "Anel das Almas",
    magnum: "Munição Magnum",
    kodachi: "Kodachi",
    whistle: "Apito",
    jettFists: "Punhos do Jett",
    mass: "Distintivo",
    foxMarble: "Berlinde de Raposa",
    coreController: "Núcleo Controlador",
    charges: "Cargas",
    chessPiece: "Peça de Xadrez",
    transmitter: "Transmissor",
    wings: "Asas",
    twoHandedWeapon: "Arma de duas Mãos",
    twoHandedAxe: "Machado de duas Mãos",
    bow: "Arco",
    crossbow: "Besta",
    dualBowgun: "Bestas Duplas",
    gun: "Arma de Fogo",
    handCannon: "Canhão de Mão",
    knuckle: "Luvas de Batalha",
    twoHandedBlunt: "Porrete de duas Mãos",
    poleArm: "Arma de Haste",
    spear: "Lança",
    twoHandedSword: "Espada de duas Mãos",
    claw: "Garra",
    katana: "Katana",
    fan: "Leque",
    lapis: "Lapis",
    lazuli: "Lazuli",
    armCannon: "Canhão de Braço",
    character: "Personagem",
    face: "Face",
    hair: "Cabelo",
    monster: "Monstro",
    crusaderCodex: "Códex do Cavaleiro",
    monsterBattle: "Batalha de Monstros",
    mount: "Montaria",
    consumable: "Consumível",
    potion: "Poção",
    foodAndDrink: "Comida e Bebida",
    statusCure: "Cura de Status",
    petFood: "Comida de Mascote",
    transformation: "Transformação",
    eXPPotion: "Poção de EXP",
    characterModification: "Modificação de Personagem",
    timeSaver: "Economizador de Tempo",
    equipmentBox: "Caixa de Equipamento",
    teleportItem: "Item de Teleporte",
    eXPBuff: "Buff de EXP",
    appearance: "Aparência",
    armorScroll: "Pergaminho de Armadura",
    helmet: "Capacete",
    eye: "Olho",
    topwear: "Camisa",
    bottomwear: "Calça",
    gloves: "Luvas",
    belts: "Cintos",
    shoulder: "Ombreira",
    equipment: "Equipamento",
    pet: "Mascote",
    weaponScroll: "Pergaminho de Arma",
    oneHandedBW: "AdH de uma Mão",
    twoHandedBW: "AdH de duas Mãos",
    polearm: "Arma de Haste",
    zero: "Zero",
    specialScroll: "Pergaminho Especial",
    cleanSlateScroll: "Pergaminho de Limpeza",
    slotCarver: "Escultor de Encaixe",
    bonusPotentialScroll: "Pergaminho de Potencial Bônus",
    chaosScroll: "Pergaminho do Caos",
    equipEnhancement: "Melhora de Equipamento",
    potentialScroll: "Pergaminho de Potencial",
    awakeningStamp: "Selo do Despertar",
    innocenceScroll: "Pergaminho da Inocência",
    fusionAnvil: "Bigorna de Fusão",
    whiteScroll: "Pergaminho Branco",
    hammer: "Martelo",
    magnifyingGlass: "Lupa",
    timeExtension: "Extensão de Tempo",
    luckyDayScroll: "Pergaminho do dia de Sorte",
    protectionScroll: "Pergaminho de Proteção",
    safetyScroll: "Pergaminho de Segurança",
    guardianScroll: "Pergaminho Guardião",
    equipmentChange: "Troca de Equipamento",
    soulWeapon: "Arma das Almas",
    miracleCube: "Cubo Milagroso",
    trade: "Troca",
    masteryBook: "Livro de Mastria",
    aPSPReset: "Reiniciar AP/SP",
    monsterTaming: "Domesticação de Monstro",
    circulator: "Circulador",
    dust: "Pó",
    tablet: "Tablete",
    projectile: "Projétil",
    arrow: "Flecha",
    thrown: "Arremessado",
    crossbowBolt: "Tiro de Besta",
    bullet: "Bala",
    monsterFamiliar: "Monstro/Familiar",
    monsterCard: "Carta de Monstro",
    familiarSkill: "Habilidade do Familiar",
    familiarCard: "Carta de Familiar",
    recipe: "Receita",
    smithingRecipe: "Fórmula de Metalurgia",
    accessoryCraftingRecipe: "Receita de Confecção de Acessório",
    alchemyRecipe: "Fórmula de Alquimia",
    synergyMachine: "Máquina de Sinergia",
    megaphone: "Megafone",
    summoningSack: "Saco de Invocação",
    message: "Mensagem",
    lieDetector: "Detector de Mentiras",
    wedding: "Casamento",
    owl: "Coruja",
    capsule: "Capsula",
    itemPot: "Pote de Item",
    monsterDrop: "Drop de Monstro",
    questItem: "Item de Quest",
    cosmetic: "Cosmético",
    minigame: "Minigame",
    petCommand: "Comando de Mascote",
    book: "Livro",
    effect: "Efeito",
    coin: "Moeda",
    container: "Recipiente",
    eXPTicket: "Ticket de EXP",
    eventItem: "Item de Evento",
    cashShop: "Loja de Cash",
    rewardItem: "Item Premium",
    potentialLock: "Travar Potencial",
    fusion: "Fusão",
    coupon: "Cupom",
    crafting: "Confecção",
    mineralOre: "Minério",
    mineralProcessed: "Minério Processado",
    rareOre: "Minério Raro",
    rareProcessedOre: "Minério Raro Processado",
    craftingItem: "Item de Confecção",
    herb: "Erva",
    herbOil: "Óleo de Erva",
    maker: "Fabricante",
    chair: "Cadeira",
    extractor: "Extrator",
    title: "Título",
    decoration: "Decoração",
    key: "Chave",
    commerci: "Commerci",
    tradeGood: "Trocar Bens",
    shipEnhancement: "Melhoramento de Navio",
    evolutionLab: "Laboratório da Evolução",
    core: "Núcleo",
    mission: "Missão",
    nebulite: "Nebulite",
    rankDNebulite: "Nebulite tipo D",
    rankCNebulite: "Nebulite tipo C",
    rankBNebulite: "Nebulite tipo B",
    rankANebulite: "Nebulite tipo A",
    rankSNebulite: "Nebulite tipo S",
    teleportRock: "Pedra de Teleporte",
    package: "Pacote",
    itemStore: "Loja de Itens",
    questHelper: "Auxiliar de Quests",
    randomReward: "Recompensa Aleatória",
    specialItem: "Item Especial",
    gachapon: "Gachapon",
    surpriseBox: "Caixa Surpresa",
    exchangeCoupon: "Cupom de Troca",
    equipmentModification: "Modificação de Equipamento",
    scroll: "Pergaminho",
    upgradeSlot: "Melhorar Encaixe",
    sPAPModification: "Modificação de SP/AP",
    protection: "Proteção",
    eXPCoupon: "Cupom de EXP",
    dropCoupon: "Cupom de Drop",
    entryPass: "Passe de Entrada",
    inventorySlot: "Encaixe de Inventário",
    eXPCoupons: "Cupons de EXP",
    skillModification: "Modificação de Habilidade",
    weapon: "Arma",
    hairCoupon: "Cupom de Cabelo",
    hairColorCoupon: "Cupom de cor de Cabelo",
    faceCoupon: "Cupom de Face",
    cosmeticLens: "Lentes Cosméticas",
    skinCoupon: "Cupom de Pele",
    ear: "Orelha",
    facialExpression: "Expressão Facial",
    special: "Especial",
    petAppearance: "Aparência de Mascote",
    freeMarket: "Mercado Livre",
    hiredMerchant: "Mercador Contratado",
    storePermit: "Autorização de Loja",
    auctionHouse: "Casa de Leilão",
    messengerAndSocial: "Mensageiro e Social",
    weatherEffect: "Efeito Climático",
    messenger: "Mensageiro",
    kite: "Pipa",
    note: "Nota",
    song: "Música",
    characterEffect: "Efeito de Personagem",
    guildForumEmoticon: "Emoticom de Fórum de Guilda",
    messageboard: "Quadro de Mensagens",
    miscellaneous: "Diversos",
    cakeVsPie: "Bolo vs Torta",
    language: 'Idioma',
    playMusic: 'Tocar Música'
  },
  nl: {
    understand: 'Ik begrijp het',
    genderFilter: 'Geslachtsfilter',
    male: 'Man',
    female: 'Vrouw',
    quickView: 'Snel Overzicht',
    removeAll: 'Verwijder Alles',
    alpha: 'Openbare Alfa',
    unofficialNotice: 'De onofficiële MapleStory simulator en ontwerper.',
    properties: 'Eigenschappen',
    expression: 'Gezichtsuitdrukkingen',
    action: 'Pose / Actie',
    skin: 'Huid',
    universal: 'Universeel',
    character: 'Personage',
    name: 'Naam',
    illiumEars: 'Illium Oren',
    mercEars: 'Mercedes Oren',
    animate: 'Animeer',
    addBgToGif: 'Voeg achtergrond toe aan GIF',
    visible: 'Zichtbaar',
    flipHorizontal: 'Horizontaal Omdraaien',
    footholdSnapping: 'Foothold Snappen',
    lock: 'Slot',
    willDownloadZip: 'Zal een .zip downloaden',
    downloadLayeredSpriteSheet: 'Download Gelaagde Spritesheet',
    requiresPDNPlugin: 'Vereist PDN Plugin',
    deleteCharacter: 'Verwijder Personage',
    petType: 'Type Diertje',
    deletePet: 'Verwijder Diertje',
    accessory: "Accessoire",
    belt: "Riem",
    earrings: "Oorbellen",
    emblem: "Embleem",
    faceAccessory: "Gezichtsaccessoire",
    medal: "Medaille",
    eyeDecoration: "Oog Decoratie",
    earring: "Oorbel",
    pendant: "Hanger",
    pocketItem: "Zak-Item",
    powerSource: "Energiebron",
    shoulderAccessory: "Schouder Accessoire",
    armor: "Kleding",
    hat: "Hoed",
    top: "Bovenste",
    glove: "Handschoen",
    bottom: "Onderste",
    shield: "Schild",
    shoes: "Schoenen",
    testArmor: "Test Kleding",
    other: "Anders",
    dragonEquipment: "Draak Uitrusting",
    mechanicalHeart: "Mechanisch Hart",
    mechanicEquipment: "Mechanische Uitrusting",
    petEquipment: "Uitrusting Diertje",
    bits: "Stukjes",
    shovel: "Schep",
    pickaxe: "Pikhouweel",
    petUse: "Diertje Gebruik",
    oneHandedWeapon: "Eenhandig Wapen",
    scepter: "Schepter",
    oneHandedAxe: "Eenhandige Bijl",
    katara: "Katara",
    cane: "Stok",
    dagger: "Dolk",
    whipBlade: "Zweep Mes",
    oneHandedBluntWeapon: "Eenhandige Knuppel",
    shiningRod: "Schijnende Staaf",
    soulShooter: "Ziel Schutter",
    staff: "Staf",
    oneHandedSword: "Eenhandige Zwaard",
    wand: "Toverstaf",
    testWeapon: "Test Wapen",
    psyLimiter: "Psy-beperker",
    chain: "Ketting",
    gauntlet: "Handschoen",
    secondaryWeapon: "Secundaire Wapen",
    magicMarble: "Magische Knikker",
    spellbook: "Spreukenboek",
    arrowFletching: "Pijl Fletchen",
    powderKeg: "Kruitvat",
    farSight: "Ver Zicht",
    card: "Kaart",
    ironChain: "IJzeren Ketting",
    bowThimble: "Boog Vingerhoed",
    jewel: "Juweel",
    magicArrow: "Magische Pijl",
    charm: "Amulet",
    orb: "Bol",
    rosary: "Rozenkrans",
    daggerScabbard: "Dolk Schede",
    wristBand: "Polsband",
    arrowhead: "Pijlpunt",
    jettSCore: "Jett's Kern",
    novaSEssence: "Nova's Essentie",
    soulRing: "Ziel Ring",
    whistle: "Fluitje",
    jettFists: "Jett Vuisten",
    mass: "Massa",
    foxMarble: "Vos Knikker",
    coreController: "Kern Controleur",
    charges: "Ladingen",
    chessPiece: "Schaakstuk",
    wings: "Vleugels",
    twoHandedWeapon: "Tweehandige Zwaard",
    twoHandedAxe: "Tweehandige Bijl",
    bow: "Boog",
    crossbow: "Kruisboog",
    dualBowgun: "Dual Bowgun",
    gun: "Pistool",
    handCannon: "Hand Kanon",
    knuckle: "Knokkel",
    twoHandedBlunt: "Tweehandige Knuppel Wapen",
    poleArm: "Hellebaard",
    spear: "Speer",
    twoHandedSword: "Tweehandige Zwaard",
    claw: "Klauw",
    fan: "Waaier",
    armCannon: "Arm Kanon",
    character: "Personage",
    face: "Gezicht",
    hair: "Haar",
    crusaderCodex: "Kruisvaarder Codex",
    monsterBattle: "Monster Strijd",
    mount: "Rijdier",
    consumable: "Consumptie",
    potion: "Toverdrank",
    foodAndDrink: "Eten en Drinken",
    statusCure: "Status Genezing",
    petFood: "Diertje Voedsel",
    transformation: "Transformatie",
    eXPPotion: "EXP Toverdrank",
    characterModification: "Personage Modificatie",
    timeSaver: "Tijdsbespaarder",
    equipmentBox: "Uitrusting Doos",
    appearance: "Uiterlijk",
    armorScroll: "Kleding Scroll",
    helmet: "Helm",
    eye: "Oog",
    topwear: "Bovenste",
    bottomwear: "Onderste",
    gloves: "Handschoenen",
    belts: "Riemen",
    shoulder: "Schouder",
    equipment: "Uitrusting",
    pet: "Diertje",
    weaponScroll: "Wapen Scroll",
    oneHandedBW: "Eenhandig Knuppel Wapen",
    twoHandedBW: "Tweehandig Knuppel Wapen",
    polearm: "Hellebaard",
    specialScroll: "Speciale Scroll",
    cleanSlateScroll: "Schone Lei Scroll",
    bonusPotentialScroll: "Bonus Potentieel Scroll",
    chaosScroll: "Chaos Scroll",
    equipEnhancement: "Uitrusting Verbetering",
    potentialScroll: "Potentieel Scroll",
    awakeningStamp: "Ontwakening Stamp",
    innocenceScroll: "Onschuld Scroll",
    fusionAnvil: "Fusie Aambeeld",
    whiteScroll: "Witte Scroll",
    hammer: "Hamer",
    magnifyingGlass: "Vergrootglas",
    timeExtension: "Tijdsverlenging",
    luckyDayScroll: "Geluksdag Scroll",
    protectionScroll: "Protectie Scroll",
    safetyScroll: "Veiligheid Scroll",
    guardianScroll: "Bewaker Scroll",
    equipmentChange: "Uitrusting Verandering",
    soulWeapon: "Ziel Wapen",
    miracleCube: "Wonder Kubus",
    trade: "Ruil",
    masteryBook: "Meesterschap Boek",
    monsterTaming: "Monster Temming",
    dust: "Stof",
    projectile: "Projectiel",
    arrow: "Pijl",
    thrown: "Gegooid",
    crossbowBolt: "Kruisboog Bolt",
    bullet: "Kogel",
    monsterCard: "Monster Kaart",
    recipe: "Recept",
    smithingRecipe: "Smeed Recept",
    accessoryCraftingRecipe: "Accessoire Recept",
    alchemyRecipe: "Alchimie Recept",
    synergyMachine: "Synergie Machine",
    megaphone: "Megafoon",
    summoningSack: "Bijeenroepingszak",
    message: "Bericht",
    lieDetector: "Leugendetector",
    wedding: "Trouwerij",
    owl: "Uil",
    questItem: "Zoektocht Item",
    cosmetic: "Cosmetisch",
    petCommand: "Diertje Commando",
    book: "Boek",
    coin: "Munt",
    container: "Houder",
    rewardItem: "Beloningsitem",
    potentialLock: "Potentieel Slot",
    fusion: "Fusie",
    coupon: "Coupon",
    crafting: "Bouwen",
    mineralOre: "Mineraalerts",
    mineralProcessed: "Verwerkt Mineraal",
    rareOre: "Zeldzame Erts",
    rareProcessedOre: "Zeldzame Vewerkte Erts",
    craftingItem: "Bouw Item",
    herb: "Kruid",
    herbOil: "Kruidenolie",
    chair: "Stoel",
    extractor: "Extractor",
    title: "Titel",
    decoration: "Decoratie",
    key: "Sleutel",
    tradeGood: "Trade Good",
    shipEnhancement: "Schip Verbetering",
    evolutionLab: "Evolutie Lab",
    core: "Kern",
    mission: "Missie",
    teleportRock: "Teleport Rock",
    package: "Verpakking",
    itemStore: "Item Winkel",
    randomReward: "Random Beloning",
    specialItem: "Speciaal Item",
    surpriseBox: "Verassingsdoos",
    exchangeCoupon: "Uitwisseling Coupon",
    equipmentModification: "Uitrusting Modificatie",
    sPAPModification: "SP/AP Modificatie",
    protection: "Protectie",
    eXPCoupon: "EXP Coupon",
    dropCoupon: "Drop Coupon",
    entryPass: "Entree Pas",
    inventorySlot: "Inventory Slot",
    eXPCoupons: "EXP Couponnen",
    skillModification: "Skill Modificatie",
    weapon: "Wapen",
    hairCoupon: "Haar Coupon",
    hairColorCoupon: "Haarkleur Coupon",
    faceCoupon: "Gezicht Coupon",
    cosmeticLens: "Cosmetische Lens",
    skinCoupon: "Huid Coupon",
    ear: "Oor",
    facialExpression: "Gezichtsuitdrukking",
    special: "Speciaal",
    petAppearance: "Diertje uiterlijk",
    freeMarket: "Vrije Markt",
    hiredMerchant: "Gehuurde Handelaar",
    storePermit: "Winkel Vergunning",
    auctionHouse: "Veilingshuis",
    messengerAndSocial: "Messenger and Sociaal",
    weatherEffect: "Weer Effect",
    kite: "Vlieger",
    note: "Notitie",
    song: "Lied",
    characterEffect: "Personage Effect",
    guildForumEmoticon: "Gilde Forum Emoticon",
    messageboard: "Prikbord",
    miscellaneous: "Diversen",
    cakeVsPie: "Cake vs Taart",
    language: 'Taal',
    playMusic: 'Speel Muziek'
  },
  jp: {
    maplestory: 'メイプルストーリー',
    understand: 'わかります',
    isCashFilter: 'キャッシュショップの商品のみ',
    genderFilter: '性別',
    male: '男性',
    female: '女性',
    quickView: '早見',
    removeAll: 'すべて削除',
    alpha: 'アルファバージョン',
    unofficialNotice: '非公式のム メイプルストーリーシミュレータ',
    properties: '特性',
    expression: '表情',
    action: 'ポーズ',
    skin: '肌の色',
    zoom: 'ズームレンズ'
  },
  kr: {
    maplestory: "메이플스토리", //(space removed)
    understand: "동의합니다", //(edited from 이해 했어)
    isCashFilter: "캐시샵 상품 만",
    genderFilter: "성별",
    male: "남성",
    female: "여성",
    quickView: "퀵뷰",
    removeAll: "모두 제거",
    alpha: "알파 버전",
    unofficialNotice: "비공식 메이플스토리 코디 시뮬레이터.", //(space removed, smoothen)
    properties: "특성",
    expression: "표정",
    action: "자세",
    skin: "피부색",
    zoom: "줌",
    universal: "양성",
    pet: "펫",
    character: "캐릭터",
    name: "이름",
    illiumEars: "일리움 귀",
    mercEars: "메르세데스 귀",
    frame: "프레임",
    animate: "영상으로 보기",
    addBgToGif: "Add BG to GIF",
    visible: "보이게 하기",
    flipHorizontal: "가로로 뒤집기",
    footholdSnapping: "Foothold Snapping",
    lock: "락",
    downloadSpriteSheet: "스프라이트시트 다운로드",
    willDownloadZip: ".zip 다운로드",
    downloadLayeredSpriteSheet: "레이어드 스프레드시트 다운로드",
    requiresPDNPlugin: "PDN 플러그인 필요",
    deleteCharacter: "캐릭터 삭제",
    petType: "펫 종류",
    deletePet: "펫 삭제",
    accessory: "장신구",
    badge: "뱃지",
    belt: "벨트",
    earrings: "귀걸이",
    emblem: "엠블렘",
    faceAccessory: "얼굴장식",
    medal: "훈장",
    eyeDecoration: "눈장식",
    earring: "귀걸이",
    ring: "반지",
    pendant: "펜던트",
    pocketItem: "포켓 아이템",
    powerSource: "하이브리드 하트",
    shoulderAccessory: "견장",
    totem: "토템",
    armor: "방어구",
    hat: "모자",
    cape: "망토",
    top: "상의",
    glove: "장갑",
    overall: "한벌옷",
    bottom: "하의",
    shield: "방패",
    shoes: "신발",
    testArmor: "Test Armor",
    other: "그 외",
    android: "안드로이드",
    dragonEquipment: "드래곤 장비",
    mechanicalHeart: "안드로이드 심장",
    mechanicEquipment: "메카닉 장비",
    petEquipment: "펫 장비",
    bits: "Bits",
    shovel: "삽",
    pickaxe: "곡괭이",
    skillEffect: "스킬 이펙트",
    petUse: "Pet Use",
    oneHandedWeapon: "한손무기",
    scepter: "Scepter", //(not in kr server)
    oneHandedAxe: "한손도끼",
    katara: "카타나",
    cane: "케인",
    dagger: "대거",
    desperado: "데스페라도",
    whipBlade: "에너지 소드",
    oneHandedBluntWeapon: "한손둔기",
    shiningRod: "샤이닝 로드",
    soulShooter: "소울 슈터",
    staff: "스태프",
    oneHandedSword: "한손검",
    wand: "완드",
    testWeapon: "Test Weapon",
    cash: "캐시",
    psyLimiter: "ESP 리미터",
    chain: "체인",
    gauntlet: "건틀렛",
    secondaryWeapon: "보조무기",
    magicMarble: "Magic Marble",
    spellbook: "마도서",
    arrowFletching: "화살깃",
    powderKeg: "화약통",
    farSight: "조준기",
    card: "카드",
    ironChain: "쇠사슬",
    bowThimble: "활골무",
    jewel: "쥬얼리",
    document: "문서",
    magicArrow: "마법화살",
    charm: "매력",
    orb: "오브",
    rosary: "로자리오",
    daggerScabbard: "단검용 검집",
    wristBand: "리스트밴드",
    arrowhead: "화살촉",
    jettSCore: "Jett's Core", //(not in kr server)
    novaSEssence: "용의 정수",
    soulRing: "소울링",
    magnum: "매그넘",
    kodachi: "Kodachi", //(not in kr server)
    whistle: "Whistle", //(not in kr server)
    jettFists: "Jett Fists", //(not in kr server)
    mass: "무게추",
    foxMarble: "여우구슬",
    coreController: "컨트롤러",
    charges: "장약",
    chessPiece: "체스피스",
    transmitter: "무기 전송장치",
    wings: "매직윙",
    twoHandedWeapon: "두손무기",
    twoHandedAxe: "두손도끼",
    bow: "활",
    crossbow: "석궁",
    dualBowgun: "듀얼보우건",
    gun: "건",
    handCannon: "핸드캐논",
    knuckle: "너클",
    twoHandedBlunt: "두손둔기",
    poleArm: "폴암",
    spear: "창",
    twoHandedSword: "두손검",
    claw: "클로",
    katana: "카타나",
    fan: "Fan", //(not in kr server)
    lapis: "태도",
    lazuli: "대검",
    armCannon: "건틀렛 리볼버",
    face: "얼굴",
    hair: "헤어",
    monster: "몬스터",
    crusaderCodex: "Crusader Codex", //(not in kr server) - maybe 인버스 코덱스
    monsterBattle: "배틀 몬스터스 리그",
    mount: "라이딩",
    consumable: "소비",
    potion: "포션",
    foodAndDrink: "음식과 음료",
    statusCure: "상태이상 회복",
    petFood: "펫의 먹이",
    transformation: "Transformation",
    eXPPotion: "경험치 포션",
    characterModification: "Character Modification",
    timeSaver: "Time Saver",
    equipmentBox: "Equipment Box",
    teleportItem: "텔레포트",
    eXPBuff: "경험치 버프",
    appearance: "외형",
    armorScroll: "장비강화 주문서",
    helmet: "헬멧",
    eye: "눈",
    topwear: "상의",
    bottomwear: "하의",
    gloves: "장갑",
    belts: "벨트",
    shoulder: "견장",
    equipment: "장비",
    weaponScroll: "장비강화 주문서",
    oneHandedBW: "한손둔기",
    twoHandedBW: "두손둔기",
    polearm: "폴암",
    zero: "제로",
    specialScroll: "Speical Scroll",
    cleanSlateScroll: "순백의 주문서",
    slotCarver: "각인의 인장",
    bonusPotentialScroll: "고급잠재능력 부여 주문서",
    chaosScroll: "혼돈의 주문서",
    equipEnhancement: "Equip Enhancement",
    potentialScroll: "잠재능력 부여 주문서",
    awakeningStamp: "금빛 각인의 인장",
    innocenceScroll: "이노센트 주문서",
    fusionAnvil: "신비의 모루",
    whiteScroll: "백의 주문서",
    hammer: "망치",
    magnifyingGlass: "감정 돋보기",
    timeExtension: "Time Extension",
    luckyDayScroll: "럭키데이 주문서",
    protectionScroll: "프로텍트 쉴드",
    safetyScroll: "세이프티 쉴드",
    guardianScroll: "리커버리 쉴드",
    equipmentChange: "Equipment Change",
    soulWeapon: "소울웨폰",
    miracleCube: "미라클 큐브",
    trade: "교환",
    masteryBook: "마스터리북",
    aPSPReset: "AP/SP 초기화",
    monsterTaming: "Monster Taming", //(not in kr server)
    circulator: "서큘레이터",
    dust: "더스트",
    tablet: "알약",
    projectile: "투사체",
    arrow: "화살",
    thrown: "Thrown",
    crossbowBolt: "석궁전용 화살",
    bullet: "불릿",
    monsterFamiliar: "Monster/Familiar",
    monsterCard: "Monster Card",
    familiarSkill: "Familiar Skill",
    familiarCard: "Familiar Card",
    recipe: "레시피",
    smithingRecipe: "장비 제작 레시피",
    accessoryCraftingRecipe: "장신구 제작 레시피",
    alchemyRecipe: "연금술 레시피",
    synergyMachine: "Synergy Machine",
    megaphone: "메가폰",
    summoningSack: "의문의 보따리",
    message: "메시지",
    lieDetector: "거짓말 탐지기",
    wedding: "결혼식",
    owl: "부엉이",
    capsule: "캡슐",
    itemPot: "Item Pot",
    monsterDrop: "Monster Drop",
    questItem: "퀘스트 아이템",
    cosmetic: "Cosmetic",
    minigame: "미니게임",
    petCommand: "펫 명령어",
    book: "Book",
    effect: "이펙트",
    coin: "코인",
    container: "Container",
    eXPTicket: "EXP Ticket",
    eventItem: "이벤트 아이템",
    cashShop: "캐시샵",
    rewardItem: "보상 아이템",
    potentialLock: "Potential Lock",
    fusion: "장비 합성",
    coupon: "쿠폰",
    crafting: "제작",
    mineralOre: "Mineral Ore",
    mineralProcessed: "Mineral Processed",
    rareOre: "Rare Ore",
    rareProcessedOre: "Rare Processed Ore",
    craftingItem: "제작 아이템",
    herb: "허브",
    herbOil: "허브 오일",
    maker: "Maker",
    chair: "의자",
    extractor: "Extractor",
    title: "칭호",
    decoration: "Decoration",
    key: "Key",
    commerci: "Commerci",
    tradeGood: "Trade Good",
    shipEnhancement: "Ship Enhancement",
    evolutionLab: "Evolution Lab",
    core: "Core",
    mission: "Mission",
    nebulite: "네뷸라이트",
    rankDNebulite: "D랭크 네뷸라이트",
    rankCNebulite: "C랭크 네뷸라이트",
    rankBNebulite: "B랭크 네뷸라이트",
    rankANebulite: "A랭크 네뷸라이트",
    rankSNebulite: "S랭크 네뷸라이트",
    teleportRock: "순간이동의 돌",
    package: "택배",
    itemStore: "상점",
    questHelper: "Quest Helper",
    randomReward: "랜덤 보상",
    specialItem: "스페셜 아이템",
    gachapon: "Gachapon", //(not in kr server)
    surpriseBox: "Surprise Box",
    exchangeCoupon: "교환 쿠폰",
    equipmentModification: "장비강화",
    scroll: "주문서",
    upgradeSlot: "슬롯 강화",
    sPAPModification: "SP/AP Modification",
    protection: "Protection",
    eXPCoupon: "경험치 쿠폰",
    dropCoupon: "드롭률 쿠폰",
    entryPass: "입장권",
    inventorySlot: "인벤토리 슬롯",
    eXPCoupons: "경험치 쿠폰",
    skillModification: "스킬 강화",
    weapon: "무기",
    hairCoupon: "헤어쿠폰",
    hairColorCoupon: "헤어염색쿠폰",
    faceCoupon: "성형쿠폰",
    cosmeticLens: "카르타의 진주",
    skinCoupon: "스킨케어",
    ear: "귀",
    facialExpression: "표정",
    special: "스페셜",
    petAppearance: "펫 외형",
    freeMarket: "자유시장",
    hiredMerchant: "고용상인",
    storePermit: "개인상점",
    auctionHouse: "경매장",
    messengerAndSocial: "메신저와 소셜",
    weatherEffect: "날씨효과",
    messenger: "메신저",
    kite: "Kite",
    note: "Note",
    song: "노래",
    characterEffect: "캐릭터 이펙트",
    guildForumEmoticon: "길드 포럼 이모티콘",
    messageboard: "게시판",
    miscellaneous: "그 외",
    cakeVsPie: "케이크 vs 파이",
    language: "언어",
    playMusic: "음악 재생",
    region: "지역",
    version: "버전",
    gender: "성별"
  },
  ch: {
    maplestory: '楓之谷',
    understand: '我知道了!',
    isCashFilter: '現金道具',
    genderFilter: '性別設定',
    male: '男性',
    female: '女性',
    quickView: '道具清單',
    removeAll: '移除全部',
    alpha: '預覽版本',
    unofficialNotice: '非官方製作的楓之谷模擬器 / 設計.',
    properties: '屬性',
    expression: '表情',
    action: '姿勢 / 動作',
    skin: '皮膚',
    zoom: '放大',
    universal: '共用',
    pet: '寵物',
    character: '人物',
    name: '名字',
    illiumEars: '伊利恩耳朵',
    mercEars: '精靈耳朵',
    frame: '影格',
    animate: '動畫',
    addBgToGif: '儲存為GIF',
    visible: '顯示',
    flipHorizontal: '水平翻轉',
    footholdSnapping: '漂浮設定',
    lock: '鎖定',
    downloadSpriteSheet: '下載行走圖',
    willDownloadZip: '檔案為 .zip',
    downloadLayeredSpriteSheet: '下載行走圖圖層分解',
    requiresPDNPlugin: '需要PDN檔案',
    deleteCharacter: '刪除角色',
    petType: '寵物類型',
    deletePet: '刪除寵物',
    light: '光',
    ashen: '淺藍',
    palePink: '淡粉',
    tanned: '曬黑',
    pale: '蒼白',
    green: '綠色',
    ghostly: '鬼魅',
    dark: '黑暗',
    clay: '泥土',
    white: '白色',
    mercedes: '精靈',
    accessory: "飾品",
    badge: "圖騰",
    belt: "腰帶",
    earrings: "耳環",
    emblem: "徽章",
    faceAccessory: "臉部裝飾",
    medal: "勳章",
    eyeDecoration: "眼鏡",
    earring: "耳環",
    ring: "戒指",
    pendant: "項鍊",
    pocketItem: "口袋道具",
    powerSource: "電源供應",
    shoulderAccessory: "護肩",
    totem: "圖騰",
    armor: "裝備",
    hat: "帽子",
    cape: "披風",
    top: "上衣",
    glove: "手套",
    overall: "套裝",
    bottom: "褲子",
    shield: "盾牌",
    shoes: "鞋子",
    testArmor: "測試裝備",
    other: "其他",
    android: "機器人",
    dragonEquipment: "龍裝備",
    mechanicalHeart: "機器人心臟",
    mechanicEquipment: "機器人裝備",
    petEquipment: "寵物裝備",
    bits: "拼圖",
    shovel: "鏟子",
    pickaxe: "十字鎬",
    skillEffect: "技能效果",
    petUse: "使用寵物",
    oneHandedWeapon: "單手武器",
    scepter: "幻獸棍棒",
    oneHandedAxe: "單手斧",
    katara: "雙刀",
    cane: "手杖",
    dagger: "短劍",
    desperado: "魔劍",
    whipBlade: "能量劍",
    oneHandedBluntWeapon: "單手槌",
    shiningRod: "閃亮克魯",
    soulShooter: "靈魂射手",
    staff: "長杖",
    oneHandedSword: "單手劍",
    wand: "短杖",
    testWeapon: "測試武器",
    cash: "現金",
    psyLimiter: "ESP限制器",
    chain: "鎖鏈",
    gauntlet: "長手套",
    secondaryWeapon: "次要武器",
    ballast: "壓艙物",
    magicMarble: "魔術大理石",
    spellbook: "拼寫書",
    arrowFletching: "弓箭射擊",
    powderKeg: "粉末桶",
    farSight: "望遠鏡",
    card: "卡片",
    ironChain: "鎖鏈",
    bowThimble: "弓頂針",
    jewel: "寶石",
    document: "文件",
    magicArrow: "魔法劍",
    charm: "魅力",
    orb: "球",
    rosary: "念珠",
    daggerScabbard: "匕首刀鞘",
    wristBand: "腕帶",
    arrowhead: "箭頭",
    jettSCore: "傑特的核心",
    novaSEssence: "Nova本質",
    soulRing: "靈魂戒指",
    magnum: "子彈",
    kodachi: "小太刀",
    whistle: "哨子",
    jettFists: "傑特拳頭",
    mass: "塊",
    foxMarble: "狐狸大理石",
    coreController: "核心控制器",
    charges: "收費",
    chessPiece: "棋子盤",
    transmitter: "發射機",
    wings: "翅膀",
    twoHandedWeapon: "雙手武器",
    twoHandedAxe: "雙手斧",
    bow: "弓",
    crossbow: "弩",
    dualBowgun: "雙弩槍",
    gun: "槍",
    handCannon: "加農砲",
    knuckle: "指虎",
    twoHandedBlunt: "雙手槌",
    poleArm: "長矛",
    spear: "長槍",
    twoHandedSword: "雙手劍",
    claw: "拳套",
    katana: "太刀",
    fan: "扇子",
    lapis: "琉",
    lazuli: "璃",
    armCannon: "重拳槍",
    character: "角色",
    face: "臉部",
    hair: "頭髮",
    monster: "怪物",
    crusaderCodex: "怪物圖騰",
    monsterBattle: "怪物戰鬥",
    mount: "安裝",
    consumable: "耗材",
    potion: "劑",
    foodAndDrink: "食物和飲料",
    statusCure: "狀態治癒",
    petFood: "寵物食品",
    transformation: "轉場",
    eXPPotion: "EXP 藥劑",
    characterModification: "人物修改",
    timeSaver: "省時間",
    equipmentBox: "設備箱",
    teleportItem: "傳送物品",
    eXPBuff: "EXP加持",
    appearance: "外觀",
    armorScroll: "裝備卷軸",
    helmet: "頭盔",
    eye: "眼睛",
    topwear: "上衣穿著",
    bottomwear: "下衣穿著",
    gloves: "手套",
    belts: "腰帶",
    shoulder: "肩膀",
    equipment: "設備",
    pet: "寵物",
    weaponScroll: "武器卷軸",
    oneHandedBW: "單手 BW",
    twoHandedBW: "雙手 BW",
    polearm: "長柄武器",
    zero: "零",
    specialScroll: "特別卷軸",
    cleanSlateScroll: "乾淨卷軸",
    slotCarver: "老虎雕刻機",
    bonusPotentialScroll: "加倍潛力卷軸",
    chaosScroll: "混沌卷軸",
    equipEnhancement: "星級卷軸",
    potentialScroll: "潛在卷軸l",
    awakeningStamp: "覺醒印章",
    innocenceScroll: "純真卷軸",
    fusionAnvil: "融合鐵砧",
    whiteScroll: "白色滾動",
    hammer: "槌子",
    magnifyingGlass: "放大鏡",
    timeExtension: "時間延長",
    luckyDayScroll: "幸福日卷軸",
    protectionScroll: "保護卷軸",
    safetyScroll: "安全卷軸",
    guardianScroll: "監護卷軸",
    equipmentChange: "設備更換",
    soulWeapon: "靈魂武器",
    miracleCube: "奇蹟方體",
    trade: "貿易",
    masteryBook: "通達書",
    aPSPReset: "AP/SP 重新設定",
    monsterTaming: "怪物馴服",
    circulator: "循環",
    dust: "塵土",
    tablet: "牌",
    projectile: "拋射物",
    arrow: "箭",
    thrown: "投擲",
    crossbowBolt: "弩箭",
    bullet: "子彈",
    monsterFamiliar: "怪物/熟練",
    monsterCard: "怪物卡片",
    familiarSkill: "熟練技能",
    familiarCard: "熟練卡片",
    recipe: "配方",
    smithingRecipe: "鍛造配方",
    accessoryCraftingRecipe: "配件製作配方",
    alchemyRecipe: "煉金術配方",
    synergyMachine: "協同機器",
    megaphone: "擴音器",
    summoningSack: "召喚袋",
    message: "訊息",
    lieDetector: "測謊器",
    wedding: "結婚",
    owl: "貓頭鷹",
    capsule: "膠囊",
    itemPot: "培養皿",
    monsterDrop: "怪物丟棄",
    questItem: "問題道具",
    cosmetic: "化妝品",
    minigame: "小遊戲",
    petCommand: "寵物指揮",
    book: "書",
    effect: "技能",
    coin: "金幣",
    container: "容器",
    eXPTicket: "EXP 券",
    eventItem: "活動道具",
    cashShop: "商城",
    rewardItem: "獎勵項目",
    potentialLock: "潛在鎖定",
    fusion: "融合",
    coupon: "券",
    crafting: "手工藝",
    mineralOre: "礦物礦石",
    mineralProcessed: "礦物加工",
    rareOre: "稀有礦石",
    rareProcessedOre: "稀有加工礦石",
    craftingItem: "製作項目",
    herb: "藥草",
    herbOil: "藥草油",
    maker: "製造者",
    chair: "椅子",
    extractor: "摘取器",
    title: "標題",
    decoration: "裝修",
    key: "鑰匙",
    commerci: "商店",
    tradeGood: "貿易好",
    shipEnhancement: "船舶增強",
    evolutionLab: "進化實驗室",
    core: "核心",
    mission: "任務",
    nebulite: "霓虹石",
    rankDNebulite: "牌位 D 星雲岩",
    rankCNebulite: "牌位 C 星雲岩",
    rankBNebulite: "牌位 B 星雲岩",
    rankANebulite: "牌位 A 星雲岩",
    rankSNebulite: "牌位 S 星雲岩",
    teleportRock: "傳送石",
    package: "包",
    itemStore: "道具商店",
    questHelper: "任務助手",
    randomReward: "隨機獎勵",
    specialItem: "特別物品",
    gachapon: "扭蛋機",
    surpriseBox: "驚喜盒",
    exchangeCoupon: "兌換優惠券",
    equipmentModification: "設備改造",
    scroll: "卷軸",
    upgradeSlot: "黃金槌子",
    sPAPModification: "SP/AP 修改",
    protection: "保護",
    eXPCoupon: "EXP 券",
    dropCoupon: "丟棄券",
    entryPass: "入場通行證",
    inventorySlot: "庫存槽",
    eXPCoupons: "EXP券",
    skillModification: "技能修改",
    weapon: "武器",
    hairCoupon: "頭髮券",
    hairColorCoupon: "頭髮顏色券",
    faceCoupon: "眼睛券",
    cosmeticLens: "化妝鏡",
    skinCoupon: "皮膚券",
    ear: "爾賭",
    facialExpression: "表情",
    special: "特別",
    petAppearance: "寵物外觀",
    freeMarket: "自由市場",
    hiredMerchant: "精靈商人",
    storePermit: "商店攤",
    auctionHouse: "拍賣",
    messengerAndSocial: "訊息和社交",
    weatherEffect: "天氣效果",
    messenger: "訊息",
    kite: "風箏",
    note: "注意",
    song: "歌曲",
    characterEffect: "角色效果",
    guildForumEmoticon: "公會論壇表情",
    messageboard: "留言板",
    miscellaneous: "雜項",
    cakeVsPie: "蛋糕 vs 派"
  }
}

export default {
  getLocalized(language) {
    return {
      ...all['en'],
      ...all[language]
    }
  }
}
