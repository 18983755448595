import React, { Component } from 'react'
import './index.css'
import ItemListing from '../ItemListing'
import EquippedItems from '../EquippedItems'
import _ from 'lodash'
import CharacterList from '../CharacterList'
import 'react-notifications/lib/notifications.css'
import {NotificationContainer, NotificationManager} from 'react-notifications'
import RenderCanvas from '../RenderCanvas'
import VirtualizedSelect from 'react-virtualized-select'
import 'react-select/dist/react-select.css'
import createFilterOptions from 'react-select-fast-filter-options'
import Slider from 'rc-slider'
import RcTooltip from 'rc-tooltip'
import { SketchPicker } from 'react-color'
import Localize from '../../const/localize'
import { Tooltip } from 'react-tippy'
import FontAwesome from 'react-fontawesome'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import 'react-tippy/dist/tippy.css';
import Toggle from 'react-toggle'

const throttledErrorNotification = _.throttle(NotificationManager.error.bind(NotificationManager), 1500, { leading:true })

function toCamel(o) {
  var newO, origKey, newKey, value
  if (o instanceof Array) {
    return o.map(function(value) {
        if (typeof value === "object") {
          value = toCamel(value)
        }
        return value
    })
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
        value = o[origKey]
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamel(value)
        }
        newO[newKey] = value
      }
    }
  }
  return newO
}

let maps = []
let mapsFilter = null
let mapPromise = fetch(`https://maplestory.io/api/${localStorage['region']}/${localStorage['version']}/map`).then(res => res.json()).then(response => {
      maps = response.map(map => {
        return {
          label: [map.streetName, map.name].join(' - '),
          value: map.id
        }
      });
      mapsFilter = createFilterOptions({options: maps})
    });

function SkinItemsFromSkinId(skinId) {
  return {
    Body: { name: 'Body', noIcon: true, id: Number(skinId), region: localStorage['region'], version: localStorage['version'], typeInfo: { overallCategory: 'Character', category: 'Character', subCategory: 'Body', lowItemId: 2000, highItemId: 2999 } },
    Head: { name: 'Head', noIcon: true, id: Number(skinId) + 10000, region: localStorage['region'], version: localStorage['version'], typeInfo: { overallCategory: 'Character', category: 'Character', subCategory: 'Head', lowItemId: 12000, highItemId: 12999 } },
  }
}

class App extends Component {
  constructor(props) {
    super(props)

    // Try to recover any existing state
    this.state = {
      characters: JSON.parse(localStorage['characters'] || 'false') || [false],
      pets: JSON.parse(localStorage['pets'] || 'false') || [],
      npcs: JSON.parse(localStorage['npcs'] || 'false') || [],
      mobs: JSON.parse(localStorage['mobs'] || 'false') || [],
      selectedIndex: JSON.parse(localStorage['selectedIndex'] || 'false') || 0,
      selectedMap: JSON.parse(localStorage['selectedMap'] || 'false') || null,
      zoom: JSON.parse(localStorage['zoom'] || 'false') || 1,
      mapPosition: {x: 0, y: 0},
      backgroundColor: JSON.parse(localStorage['backgroundColor'] || false) || {"hsl":{"h":0,"s":0,"l":0,"a":0},"hex":"transparent","rgb":{"r":0,"g":0,"b":0,"a":0},"hsv":{"h":0,"s":0,"v":0,"a":0},"oldHue":0,"source":"rgb"},
      colorPickerOpen: false,
      language: localStorage['language'] === 'undefined' ? 'en' : localStorage['language'],
      music: false,
      region: localStorage['region'],
      version: localStorage['version'],
      versions: document.mapleVersions
    }

    if (document.mapleVersions.GMS && document.mapleVersions.GMS.length > 1)
      this.state.versions = document.mapleVersions

    if (this.state.selectedIndex < 0) this.state.selectedIndex = false;
    this.state.focusRenderable = this.state.selectedIndex

    // If we have no characters at all, gen a new shell
    if (this.state.characters[0] === false)
      this.state.characters[0] = this.getNewCharacter()

      if (localStorage['currentCharacter']) {
      this.state = JSON.parse(localStorage['currentCharacter'])
      delete localStorage['currentCharacter']
      localStorage['characters'] = JSON.stringify([...this.state.characters, this.state])
      this.state['characters'] = [...this.state.characters, this.state]
    }

    this.state.characters.forEach((character, index) => {
      if (!character.id) character.id = Date.now() + (index + 1)
      character.type = 'character'
      character.action = character.action || 'stand1'
      character.frame = character.frame || 0
      character.zoom = character.zoom || 1
      character.emotion = character.emotion || 'default'
      character.skin = character.skin || 2000

      if (character.skin) {
        character.selectedItems = {
          ...SkinItemsFromSkinId(character.skin),
          ...character.selectedItems
        }
      }

      character.mercEars = character.mercEars || false
      character.illiumEars = character.illiumEars || false
      character.selectedItems = character.selectedItems || []
      character.visible = character.visible || false
      character.position = character.position || {x:0,y:0}
      character.flipX = character.flipX || false;
      character.name = character.name || '';
      character.includeBackground = character.includeBackground === undefined ? true : character.includeBackground
      let characterItems = _.values(toCamel(character.selectedItems)).map(item => {
        if (!item.region) item.region = localStorage['region']
        if (!item.version) item.version = localStorage['version']
        return item
      })
      character.selectedItems = _.keyBy(characterItems, (item) => item.typeInfo.subCategory)
      delete character.characters
      delete character.otherCharacters
      delete character.allCharacters
    })

    this.state.pets.forEach((pet, index) => {
      if (!pet.id) pet.id = Date.now() + (index + 1)
      pet.type = 'pet'
      pet.position = pet.position || { x: 0, y: 0}
      pet.summary = `https://maplestory.io/api/${this.state.region}/${this.state.version}/pet/${pet.petId}/render/${pet.animation || 'stand0'}/${pet.frame || 0}/${_.values(pet.selectedItems).map(item => item.id).join(',')}?resize=${pet.zoom || 1}`
    })

    this.state.npcs.forEach((npc, index) => {
      if (!npc.id) npc.id = Date.now() + (index + 1)
      npc.type = 'npc'
      npc.position = npc.position || { x: 0, y: 0}
      npc.summary = `https://maplestory.io/api/${this.state.region}/${this.state.version}/npc/${npc.npcId}/render/${npc.animation || 'stand'}/?resize=${npc.zoom || 1}`
    })

    this.state.mobs.forEach((mob, index) => {
      if (!mob.id) mob.id = Date.now() + (index + 1)
      mob.type = 'mob'
      mob.position = mob.position || { x: 0, y: 0}
      if (mob.animation === 'render') mob.animation = 'stand'
      mob.summary = `https://maplestory.io/api/${this.state.region}/${this.state.version}/mob/${mob.mobId}/render/${mob.animation || 'stand'}/${mob.frame || 0}/?resize=${mob.zoom || 1}`
    })

    if ((this.state.selectedIndex + 1) > (this.renderables.length) || !this.state.characters.length)
      this.state.selectedIndex = false;

    document.addEventListener("click", this.handleClick.bind(this))

    if (maps.length) this.state.mapsLoaded = true
    else mapPromise.then(() => setTimeout(() => this.setState({mapsLoaded : true}), 250))
  }

  changeRegionVersion(region, version) {
    localStorage['region'] = region
    var possibleVersions = document.mapleVersions[region]
    if (possibleVersions && possibleVersions.length > 0)
      localStorage['version'] = version || (possibleVersions[possibleVersions.length - 1]).mapleVersionId

    // Much easier than trying to reload everything here :D
    window.location.reload()
  }

  handleClick(e) {
    let element = e.target
    let found = false
    while (this.state.colorPickerOpen && !found && (element = element.parentElement) !== null) {
      if (element.className !== 'bg-color-picker-container') continue;
      else {
        found = true;
        console.log('found bg-color-picker-container')
      }
    }

    if (!found && this.state.colorPickerOpen) this.setState({ colorPickerOpen: false })
  }

  render() {
    const {
      characters,
      pets,
      npcs,
      mobs,
      selectedIndex,
      zoom,
      selectedMap,
      focusRenderable,
      backgroundColor,
      language,
      music
    } = this.state

    const localized = Localize.getLocalized(language)

    const bgColorText = `rgba(${backgroundColor.rgb.r}, ${backgroundColor.rgb.g}, ${backgroundColor.rgb.b}, ${backgroundColor.rgb.a})`

    const renderables = characters.concat(pets).concat(npcs).concat(mobs)

    return (
      <div className="App">
        <div className="App-header">
          <span className="logo">
            <b>{localized.maplestory}:</b> {localized.simulator}
          </span>
          <ul className="Nav-right">
            <li><a href='https://open.kakao.com/o/gAdw6ZWb' target="_blank">Kakao</a></li>
            <li><a href='https://discord.gg/3SyrbAs' target='_blank'>Discord</a></li>
            <li><Tooltip html={this.renderTools()} delay={[100, 300]} position='top' interactive={true} theme='light' arrow={true}>Tools</Tooltip></li>
            <li className='settings-cog'><Tooltip html={this.renderSettings()} delay={[100, 300]} position='top' interactive={true} theme='light' arrow={true}><FontAwesome name='cog' /></Tooltip></li>
          </ul>
        </div>
        <RenderCanvas
          backgroundColor={bgColorText}
          zoom={zoom}
          mapId={selectedMap}
          renderables={renderables}
          selectedRenderable={selectedIndex}
          focusRenderable={focusRenderable === undefined ? selectedIndex : focusRenderable}
          onUpdateRenderable={this.updateRenderable.bind(this)}
          onClick={this.clickCanvas.bind(this)}
          localized={localized}
          onClickRenderable={this.userUpdateSelectedRenderable.bind(this)}/>
        { (selectedIndex !== false) ?
          <ItemListing
            target={renderables[selectedIndex]}
            onItemSelected={this.userSelectedItem.bind(this)}
            localized={localized} /> : '' }
        <CharacterList
          renderables={renderables}
          selectedIndex={selectedIndex}
          onAddCharacter={this.addCharacter.bind(this)}
          onAddPet={this.addPet.bind(this)}
          onAddNPC={this.addNPC.bind(this)}
          onAddMob={this.addMob.bind(this)}
          onImportCharacter={this.importCharacter.bind(this)}
          onDeleteCharacter={this.removeCharacter.bind(this)}
          onCloneCharacter={this.cloneCharacter.bind(this)}
          onDeletePet={this.removePet.bind(this)}
          onDeleteNPC={this.removeNPC.bind(this)}
          onDeleteMob={this.removeMob.bind(this)}
          localized={localized}
          onUpdateSelectedCharacter={function (renderable) {
            this.userUpdateSelectedRenderable(renderable, () => {
              this.setState({
                focusRenderable: this.state.selectedIndex
              })
            })
          }.bind(this)}
          onUpdateCharacter={this.userUpdateCharacter.bind(this)}
          onUpdatePet={this.userUpdatePet.bind(this)}
          onUpdateNPC={this.userUpdateNPC.bind(this)}
          onUpdateMob={this.userUpdateMob.bind(this)}
          />
        {
          (selectedIndex !== false && renderables[selectedIndex] !== undefined && !_.isEmpty(renderables[selectedIndex].selectedItems) ? <EquippedItems
            equippedItems={renderables[selectedIndex].selectedItems}
            onRemoveItem={this.userRemovedItem.bind(this)}
            name={renderables[selectedIndex].name}
            skinId={renderables[selectedIndex].skin}
            onUpdateItem={this.updateItem.bind(this)}
            localized={localized}
            onRemoveItems={this.userRemovedItems.bind(this)} /> : '')
        }
        <NotificationContainer />
        { music ? <audio src={`//maplestory.io/api/${this.state.region}/${this.state.version}/map/${selectedMap}/bgm`} autoPlay={true} loop={true} /> : '' }
      </div>
    )
  }

  renderTools() {
    return (
      <span className='tools'>
        Drag this to your bookmark bar and click it to export all characters from any MapleStory Design website:
        <a href="
          javascript:(((window.allCharacters = JSON.parse(localStorage['characters']))
          .forEach(function (character, i) {
            setTimeout(function () {
              var a = document.createElement('a');
              a.style = 'display: none;';
              document.body.appendChild(a);
              var payload = JSON.stringify(character, null, 2);
              var blob = new Blob([payload], {type: 'octet/stream'});
              var url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = (character ? (character.name || 'character') : 'character') + '-data.json';
              a.click();
              window.URL.revokeObjectURL(url);
              setTimeout(function () {a.remove();}, 100);
              if (i === window.allCharacters.length - 1) alert('Done exporting all characters')
            }, i * 250)
          })))">
          Export MapleStory Design Characters
        </a>
      </span>
    )
  }

  fetchOldCharacters() {
    if (this.state.fetching) return
    this.state.fetching = true

    const actions = {
      characters: function (characters) {
        let localCharacters = JSON.parse(localStorage['characters'])
        let remoteCharacters = JSON.parse(characters.characters)
        let resultCharacters = [
          ...localCharacters,
          ...remoteCharacters
        ]

        let uniqueIds = resultCharacters.reduceRight((total, current) => {
          total[current.id] = current
          return total
        }, {})

        let uniqueResults = Object.values(uniqueIds)

        localStorage['characters'] = JSON.stringify(uniqueResults)
        window.location.reload()
      }
    }

    function receiveMessage(event) {
      if (event.origin !== window.location.origin) throw new Error("No.");
      var message = JSON.parse(event.data)

      if (message.action && actions[message.action]) {
        actions[message.action](message)
      }
    }

    const iframe = document.createElement('iframe')

    const getCharacterMessage = {
      action: "getCharacters"
    }
    iframe.onload = function () {
      const win = iframe.contentWindow
      win.addEventListener("message", receiveMessage)
      win.postMessage(JSON.stringify(getCharacterMessage), "*")
    }

    iframe.src = "http://maples.im/recover.html"
    iframe.style.position = "absolute"
    iframe.style.left = "-1000px"
    iframe.style.top = "-1000px"
    iframe.style.width = "1px"
    iframe.style.height = "1px"
    document.body.appendChild(iframe)
  }

  renderSettings() {
    const {
      zoom,
      selectedMap,
      backgroundColor,
      colorPickerOpen,
      language
    } = this.state

    const scene = {
      characters: this.state.characters,
      pets: this.state.pets,
      npcs: this.state.npcs,
      mobs: this.state.mobs,
      selectedIndex: this.state.selectedIndex,
      selectedMap: this.state.selectedMap,
      zoom: this.state.zoom,
      backgroundColor: this.state.backgroundColor,
      language: localStorage['language'] === 'undefined' ? 'en' : localStorage['language'],
      region: localStorage['region'] ? localStorage['region'] : 'GMS',
      version: localStorage['version'] ? localStorage['version'] : 'latest'
    }

    const payload = JSON.stringify(scene, null, 2)
    const sceneBlob = new Blob([payload], {type: 'octet/stream'})
    if (this.sceneBlobUrl)
      window.URL.revokeObjectURL(this.sceneBlobUrl)
    this.sceneBlobUrl = window.URL.createObjectURL(sceneBlob)

    const localized = Localize.getLocalized(language)

    const bgColorText = `rgba(${backgroundColor.rgb.r}, ${backgroundColor.rgb.g}, ${backgroundColor.rgb.b}, ${backgroundColor.rgb.a})`
    return (
      <div className='settings-container'>
        <label className='bg-color-picker-container' onClick={this.openColorPicker.bind(this)}>
        Background color
          <div className='bg-color-picker'>
            <div className='bg-color-grid' style={{ backgroundColor: bgColorText }}></div>
          </div>
          { colorPickerOpen ? <SketchPicker color={bgColorText} onChange={this.onChangeColor.bind(this)} /> : '' }
        </label>
        <label className='canvas-zoom'>
          <span>{localized.zoom}</span>
          <Slider
            value={zoom || 1}
            min={0.25}
            max={2}
            step={0.25}
            handle={handle}
            onChange={this.changeZoom.bind(this)} />
        </label>
        <label className='canvas-zoom'>
          <span>{localized.language}</span>
          <select value={this.state.language} onChange={this.changeLanguage.bind(this)}>
            <option value='en'>English</option>
            <option value='jp'>Japanese</option>
            <option value='kr'>Korean</option>
            <option value='ch'>Chinese (Traditional)</option>
            <option value='nl'>Dutch</option>
            <option value='br'>Portuguese (Brazil)</option>
          </select>
        </label>
        <div>
          <div className='map-select-container'>
            <VirtualizedSelect
              filterOptions={mapsFilter}
              isLoading={maps.length === 0}
              name='map-selection'
              searchable
              clearable
              simpleValue
              value={selectedMap}
              onChange={this.selectMap.bind(this)}
              options={maps}
              maxHeight={400}
              styles={{
                menuList: (styles, {data}) => {
                  return {
                    ...styles,
                    height: '400px'
                  }
                },
                menu: (styles, {data}) => {
                  return {
                    ...styles,
                    height: '400px'
                  }
                }
              }}
              />
          </div>
        </div>
        { Object.keys(this.state.versions).length && this.RenderRegionSelect(localized) }
        { this.state.versions[this.state.region] && this.RenderVersionSelect(localized) }
        <label>
          <span>{localized.playMusic}</span>
          <Toggle
            onChange={this.toggleMusic.bind(this)}
            checked={this.state.music} />
        </label>
        <label>
          <a href='#' onClick={this.exportAllCharacters.bind(this)}>Export All Characters</a>
          <a href={this.sceneBlobUrl} download='maplesim-scene.json'>Export Scene</a>
        </label>
        <label>
          <a href='#' onClick={this.recoverCharacterClick.bind(this)}>Recover Character</a>
          <span><input type='file' style={{display: 'none'}} id='recoverCharacter' onChange={this.recoverCharacter.bind(this)} /></span>
          <span><input type='file' style={{display: 'none'}} id='importScene' onChange={this.importScene.bind(this)} /></span>
          <a href='#' onClick={this.importSceneClick.bind(this)}>Import Scene</a>
        </label>
      </div>
    )
  }

  importSceneClick() {
    document.getElementById('importScene').click()
  }

  recoverCharacterClick() {
    // Prevent duplicate recovering
    if (window.recoveringCharacters) {
      console.warn('Already recovering characters...')
      return
    }
    document.getElementById('recoverCharacter').click()
  }

  RenderRegionSelect(localized) {
    return <label>
      <span>{localized.region}</span>
      <select value={this.state.region} onChange={(e) => this.changeRegionVersion(e.target.value)}>
        { _.keys(this.state.versions).map(versionName => <option value={versionName} key={versionName}>{versionName}</option>) }
      </select>
    </label>
  }

  RenderVersionSelect(localized) {
    return <label>
      <span>{localized.version}</span>
      <select value={this.state.version} onChange={(e) => this.changeRegionVersion(this.state.region, e.target.value)}>
        { this.state.versions[this.state.region].map(({mapleVersionId}) => <option value={mapleVersionId} key={mapleVersionId}>{mapleVersionId}</option>) }
      </select>
    </label>
  }

  renderables() {
      return this.state.characters.concat(this.state.pets).concat(this.state.npcs).concat(this.state.mobs);
  }

  toggleMusic(e) {
    this.setState({
      music: !this.state.music
    })
  }

  exportAllCharacters() {
    this.state.characters.forEach(character => {
      const a = document.createElement('a')
      a.style = 'display: none;'
      document.body.appendChild(a)

      const payload = JSON.stringify(character, null, 2),
        blob = new Blob([payload], {type: 'octet/stream'}),
        url = window.URL.createObjectURL(blob)
      a.href = url
      if (character.name)
        a.download = character.name + '-data.json'
      else
        a.download = 'character-data.json'
      a.click()

      window.URL.revokeObjectURL(url)
      a.remove()
    })
  }

  changeLanguage(e) {
    this.setState({
      language: e.target.value
    })
    localStorage['language'] = e.target.value
  }

  changeZoom(newZoom) {
    this.setState({ zoom: newZoom })
    localStorage['zoom'] = newZoom
  }

  selectMap(mapId) {
    this.setState({
      selectedMap: mapId
    })
    localStorage['selectedMap'] = mapId
  }

  updateRenderable(renderable, newProps) {
    if (renderable.type === 'pet') this.userUpdatePet(renderable, newProps)
    if (renderable.type === 'character' || renderable.type === undefined) this.userUpdateCharacter(renderable, newProps)
    if (renderable.type === 'npc') this.userUpdateNPC(renderable, newProps)
    if (renderable.type === 'mob') this.userUpdateMob(renderable, newProps)
  }

  clickCanvas(e) {
    if (e.target === e.currentTarget && (this.renderables.length) > 1) {
      this.setState({ selectedIndex: false })
      localStorage['selectedIndex'] = 'false'
    }
  }

  addPet() {
    var pets = [...(this.state.pets || []), this.getNewPet()]
    this.setState({pets, selectedIndex: this.state.characters.length + pets.length - 1})
    localStorage['pets'] = JSON.stringify(pets)
  }

  removePet(pet) {
    var pets = this.state.pets.filter(c => c !== pet)
    this.setState({ pets, selectedIndex: false, zoom: 1 }) // Unselect any pet in case we delete the last pet
    localStorage['pets'] = JSON.stringify(pets)
    localStorage['selectedIndex'] = false
    localStorage['zoom'] = 1
  }

  getNewPet() {
    const andysFavePetIds = [5000000, 5000001, 5000002, 5000003, 5000004, 5000005]
    const petId = andysFavePetIds[Math.floor(Math.random() * andysFavePetIds.length)]
    return {
      petId,
      selectedItems: [],
      id: Date.now(),
      type: 'pet',
      summary: `https://maplestory.io/api/${this.state.region}/${this.state.version}/pet/${petId}/render/stand0`,
      animation: 'stand0',
      visible: true,
      frame: 0,
      zoom: 1,
      fhSnap: true,
      position: { x:0, y:0 }
    }
  }

  addNPC() {
    var npcs = [...(this.state.npcs || []), this.getNewNPC()]
    this.setState({npcs, selectedIndex: this.state.characters.length + this.state.pets.length + npcs.length - 1})
    localStorage['npcs'] = JSON.stringify(npcs)
  }

  removeNPC(npc) {
    var npcs = this.state.npcs.filter(n => n !== npc)
    this.setState({ npcs, selectedIndex: false, zoom: 1 })
    localStorage['npcs'] = JSON.stringify(npcs)
    localStorage['selectedIndex'] = false
    localStorage['zoom'] = 1
  }

  getNewNPC() {
    const npcId = 10000
    return {
      npcId,
      selectedItems: [],
      id: Date.now(),
      type: 'npc',
      summary: `https://maplestory.io/api/${this.state.region}/${this.state.version}/npc/${npcId}/render/stand`,
      animation: 'stand',
      visible: true,
      frame: 0,
      zoom: 1,
      fhSnap: true,
      position: { x:0, y:0 }
    }
  }

  addMob() {
    var mobs = [...(this.state.mobs || []), this.getNewMob()]
    this.setState({mobs, selectedIndex: this.state.characters.length + this.state.pets.length + this.state.npcs.length + mobs.length - 1})
    localStorage['mobs'] = JSON.stringify(mobs)
  }

  removeMob(mob) {
    var mobs = this.state.mobs.filter(m => m !== mob)
    this.setState({ mobs, selectedIndex: false, zoom: 1 })
    localStorage['mobs'] = JSON.stringify(mobs)
    localStorage['selectedIndex'] = false
    localStorage['zoom'] = 1
  }

  getNewMob() {
    const mobId = 100100
    return {
      mobId,
      selectedItems: [],
      id: Date.now(),
      type: 'mob',
      summary: `https://maplestory.io/api/${this.state.region}/${this.state.version}/mob/${mobId}/render/stand`,
      animation: 'stand',
      visible: true,
      frame: 0,
      zoom: 1,
      fhSnap: true,
      position: { x:0, y:0 }
    }
  }

  importScene(e) {
    let target = e.target
    let importAll = Array.prototype.map.call(target.files, file => {
      return new Promise((res, rej) => {
        let extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()
        if (extension !== 'json') {
          console.warn('Not valid JSON file')
          return
        }

        let reader = new FileReader()
        reader.onload = function (ev) {
          let payload = ev.target.result
          let data = JSON.parse(payload)

          res()

          function UniqueBy(set, uniq) {
            return Object.values(set.reduce((total, current) => {
              total[current[uniq]] = current
              return total
            }, {}))
          }

          // Characters
          let dataCharacters = data.characters || []
          let localCharacters = this.state.characters
          console.log(dataCharacters, localCharacters)
          let resultCharacters = UniqueBy([
            ...localCharacters,
            ...dataCharacters
          ], 'id')
          localStorage['characters'] = JSON.stringify(resultCharacters)

          // Pets
          let dataPets = data.pets || []
          let localPets = this.state.pets
          console.log(dataPets, localPets)
          let resultPets = UniqueBy([
            ...localPets,
            ...dataPets
          ], 'id')
          localStorage['pets'] = JSON.stringify(resultPets)

          // NPCs
          let dataNPCs = data.npcs || []
          let localNPCs = this.state.npcs
          console.log(dataNPCs, localNPCs)
          let resultNPCs = UniqueBy([
            ...localNPCs,
            ...dataNPCs
          ], 'id')
          localStorage['npcs'] = JSON.stringify(resultNPCs)

          // Mobs
          let dataMobs = data.mobs || []
          let localMobs = this.state.mobs
          console.log(dataMobs, localMobs)
          let resultMobs = UniqueBy([
            ...localMobs,
            ...dataMobs
          ], 'id')
          localStorage['mobs'] = JSON.stringify(resultMobs)

          localStorage['version'] = data.version
          localStorage['region'] = data.region

          window.location.reload()
        }.bind(this)

        reader.readAsText(file, 'UTF8')
      })
    })

    Promise.all(importAll).then(() => {
      target.value = ''
    })
  }

  importCharacter(e) {
    let target = e.target
    let importAll = Array.prototype.map.call(target.files, file => {
      return new Promise((res, rej) => {
        let extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()
        if (extension !== 'json') {
          console.warn('Not valid JSON file')
          return
        }

        let reader = new FileReader()
        reader.onload = function (ev) {
          let payload = ev.target.result
          let data = JSON.parse(payload)

          res()
          if (!data.id || data.type !== 'character' || !data.selectedItems) return

          data.id = Date.now()

          Object.values(data.selectedItems).forEach(item => {
            const regionSelected = document.mapleVersions[item.region]
            const mappedVersion = regionSelected.find(vers => vers.mapleVersionId === item.version)
            if (!mappedVersion)
              item.version = regionSelected[regionSelected.length - 1].mapleVersionId
          })

          let characters = [
            ...this.state.characters,
            data
          ]
          this.setState({
            characters,
            selectedIndex: this.state.characters.length
          })
          localStorage['characters'] = JSON.stringify(characters)
        }.bind(this)

        reader.readAsText(file, 'UTF8')
      })
    })

    Promise.all(importAll).then(() => {
      target.value = ''
    })
  }

  recoverCharacter(e) {
    let target = e.target
    // Prevent duplicate recovering
    if (window.recoveringCharacters) return
    window.recoveringCharacters = true
    let importAll = Array.prototype.map.call(target.files, (file) =>
      new Promise((res, rej) => {
        let extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()
        if (extension !== 'png') {
          console.warn('Not valid Character file')
          return
        }

        let reader = new FileReader()
        reader.onload = function (ev) {
          let payload = ev.target.result

          res(payload)
        }.bind(this)

        reader.readAsText(file, 'UTF8')
      })
    )

    Promise.all(importAll)
    .then(filePayloads => 
      filePayloads.reduce((total, payload) =>
        total.then(() => {

          // Resolve the tail meta data
          const pngEnd = payload.lastIndexOf("END")
          let meta = null;
          for (let index = pngEnd; index < payload.length; ++index) {
              try {
                meta = JSON.parse(payload.substr(index))
                break;
              } catch (err) {
                console.warn(err)
              }
          }
          
          if (!meta || !meta.url) return

          // Decode the entries
          var entries = meta.url.split('/').filter(entry => entry).map(entry => {
              try {
                var decoded = decodeURIComponent(entry);
                var json = '[' + decoded + ']'
                return JSON.parse(json)
              } catch (err) { return null; }
          }).filter(entry => entry)
          if (entries.length !== 1) return
          const recoveredCharacterItems = entries[0]
          
          // Get a new shell character and apply the skin id
          let characterData = this.getNewCharacter()
          let bodyId = 2000
          const bodyItem = recoveredCharacterItems
              .filter(item => item.itemId >= 2000 && item.itemId < 3000)
              .map(item => item.itemId)
          if (bodyItem.length)
              bodyId = bodyItem[0]
          characterData.skin = bodyId

          // Fetch meta data for other items
          console.log('Fetching items...')
          
          var itemFetchPromises = recoveredCharacterItems
              .filter(item => item.itemId >= 13000)
              .map(item => {
              let { region, version, itemId } = item
              region = region || this.state.region
              version = version || this.state.version
          
              return fetch(`https://maplestory.io/api/${region}/${version}/item/${itemId}`)
                  .then(res => res.json())
                  .then(itemData => {
                  console.log(`Fetched item ${region}/${version}/${itemId}`)
          
                  return {
                      id: itemId,
                      region,
                      version,
                      typeInfo: itemData.typeInfo
                  }
                  })
              })
          
          characterData.selectedItems = {
            ...SkinItemsFromSkinId(bodyId),
          }
          
          Promise.all(itemFetchPromises).then(fetchedItemData => {
            fetchedItemData.forEach(itemDataEntry => {
              characterData.selectedItems[itemDataEntry.typeInfo.subCategory] = itemDataEntry
            })

            let characters = [
              ...this.state.characters,
              characterData
            ]
            this.setState({
              characters,
              selectedIndex: this.state.characters.length
            })
            localStorage['characters'] = JSON.stringify(characters)
          })
        }), 
        Promise.resolve()
      )
    )
    .then(() => {
      target.value = ''
    }).catch(err => {
      alert('Something went wrong recovering your character')
    }).then(() => {
      window.recoveringCharacters = false
    })
  }

  addCharacter() {
    var characters = [ ...this.state.characters, this.getNewCharacter() ]
    this.setState({ characters, selectedIndex: this.state.characters.length })
    localStorage['characters'] = JSON.stringify(characters)
  }

  removeCharacter(character) {
    var characters = this.state.characters.filter(c => c !== character)
    this.setState({ characters, selectedIndex: false, zoom: 1 }) // Unselect any character in case we delete the last character
    localStorage['characters'] = JSON.stringify(characters)
    localStorage['selectedIndex'] = false
    localStorage['zoom'] = 1
  }

  cloneCharacter(character) {
    let characters = [
      ...this.state.characters
    ]

    let indexOfCharacter = characters.indexOf(character)
    characters.splice(indexOfCharacter + 1, 0, {
      ...character,
      id: Date.now(),
      position: {
        x: character.position.x + 100,
        y: character.position.y
      }
    })
    let newCharacterIndex = indexOfCharacter + 1

    this.setState({ characters, selectedIndex: newCharacterIndex, focusRenderable: newCharacterIndex + 1 })
    localStorage['selectedIndex'] = newCharacterIndex
    localStorage['characters'] = JSON.stringify(characters)
  }

  userUpdateSelectedRenderable(renderable, callback) {
    let selectedIndex = this.renderables().indexOf(renderable);

    this.setState({
      selectedIndex,
      zoom: 1
    }, callback)
    localStorage['selectedIndex'] = selectedIndex
    localStorage['zoom'] = 1
  }

  userUpdatePet(pet, newProps) {
    if (pet.locked === true && newProps.locked === undefined) {
      throttledErrorNotification('Pet is locked and can not be modified', '', 1000)
      return;
    }

    const pets = [...this.state.pets]
    const petIndex = pets.indexOf(pet)

    const currentPet = pets[petIndex] = {
      ...pet,
      ...newProps
    }

    currentPet.summary = `https://maplestory.io/api/${this.state.region}/${this.state.version}/pet/${currentPet.petId}/render/${currentPet.animation || 'stand0'}/${currentPet.frame || 0}/${_.values(currentPet.selectedItems).map(item => item.id).join(',')}?resize=${currentPet.zoom || 1}`

    this.setState({
        pets: pets
    })
    localStorage['pets'] = JSON.stringify(pets)
  }

  userUpdateCharacter(character, newProps) {
    if (character.locked === true && newProps.locked === undefined) {
      throttledErrorNotification('Character is locked and can not be modified', '', 1000)
      return;
    }

    const characters = [...this.state.characters]
    const characterIndex = characters.indexOf(character)

    const currentCharacter = characters[characterIndex] = {
      ...character,
      ...newProps
    }

    if (newProps.skin) {
      currentCharacter.selectedItems = {
        ...currentCharacter.selectedItems,
        ...SkinItemsFromSkinId(currentCharacter.skin)
      }
    } else {
      currentCharacter.selectedItems = {
        ...SkinItemsFromSkinId(currentCharacter.skin),
        ...currentCharacter.selectedItems
      }
    }

    this.setState({
        characters: characters
    })
    localStorage['characters'] = JSON.stringify(characters)
  }

  userUpdateNPC(npc, newProps) {
    if (npc.locked === true && newProps.locked === undefined) {
      throttledErrorNotification('NPC is locked and can not be modified', '', 1000)
      return;
    }

    const npcs = [...this.state.npcs]
    const npcIndex = npcs.indexOf(npc)

    const currentNPC = npcs[npcIndex] = {
      ...npc,
      ...newProps
    }

    currentNPC.summary = `https://maplestory.io/api/${this.state.region}/${this.state.version}/npc/${currentNPC.npcId}/render/${currentNPC.animation || 'stand'}/${currentNPC.frame || 0}?resize=${currentNPC.zoom || 1}`

    this.setState({
        npcs: npcs
    })
    localStorage['npcs'] = JSON.stringify(npcs)
  }

  userUpdateMob(mob, newProps) {
    if (mob.locked === true && newProps.locked === undefined) {
      throttledErrorNotification('Mob is locked and can not be modified', '', 1000)
      return;
    }

    const mobs = [...this.state.mobs]
    const mobIndex = mobs.indexOf(mob)

    const currentMob = mobs[mobIndex] = {
      ...mob,
      ...newProps
    }

    currentMob.summary = `https://maplestory.io/api/${this.state.region}/${this.state.version}/mob/${currentMob.mobId}/render/${currentMob.animation || 'stand'}/${currentMob.frame || 0}?resize=${currentMob.zoom || 1}`

    this.setState({
        mobs: mobs
    })
    localStorage['mobs'] = JSON.stringify(mobs)
  }

  getNewCharacter() {
    return {
      id: Date.now(),
      type: 'character',
      action: 'stand1',
      emotion: 'default',
      skin: 2000,
      zoom: 1,
      frame: 0,
      mercEars: false,
      illiumEars: false,
      selectedItems: {
        ...SkinItemsFromSkinId(2000)
      },
      visible: true,
      position: {x: 0, y: 0},
      fhSnap: true
    }
  }

  getCurrentSelectedRenderable() {
    let max = this.state.characters.length
    if (this.state.selectedIndex < max)
        return this.state.characters[this.state.selectedIndex];
    let min = max
    max = max + this.state.pets.length
    if (this.state.selectedIndex >= min && this.state.selectedIndex < max)
        return this.state.pets[this.state.selectedIndex - min];
    min = max
    max = max + this.state.npcs.length
    if (this.state.selectedIndex >= min && this.state.selectedIndex < max)
        return this.state.npcs[this.state.selectedIndex - min];
    min = max
    max = max + this.state.mobs.length
    if (this.state.selectedIndex >= min && this.state.selectedIndex < max)
        return this.state.mobs[this.state.selectedIndex - min];
  }

  updateSelectedRenderable(props) {
    let selectedRenderable = this.getCurrentSelectedRenderable()
    switch (selectedRenderable.type.toLowerCase()) {
      case 'npc':
        return this.userUpdateNPC(selectedRenderable, props);
      case 'mob':
        return this.userUpdateMob(selectedRenderable, props);
      case 'pet':
        return this.userUpdatePet(selectedRenderable, props);
      case 'character':
        return this.userUpdateCharacter(selectedRenderable, props);
    }
  }

  userSelectedItem (item) {
    let selectedRenderable = this.getCurrentSelectedRenderable()

    item.region = localStorage['region']
    item.version = localStorage['version']

    let selectedItems = {
      ...selectedRenderable.selectedItems,
    }

    if (item.typeInfo) {
      if (item.typeInfo.subCategory === 'Overall') {
        delete selectedItems['Top']
        delete selectedItems['Bottom']
      }
    }

    if (item.similar) {
      item = { ...item }
      delete item['similar']
    }

    if (item.typeInfo) {
      selectedItems[item.typeInfo.subCategory] = item
    }
    this.updateItems(selectedItems)
  }

  userRemovedItem (item) {
    const renderables = this.state.characters.concat(this.state.pets);

    let selectedItems = {
      ...renderables[this.state.selectedIndex].selectedItems,
    }
    delete selectedItems[item.typeInfo.subCategory]
    this.updateItems(selectedItems);
  }

  userRemovedItems () {
    let selectedItems = {}
    this.updateItems(selectedItems);
  }

  updateItem (item, newProps) {
    const renderables = this.state.characters.concat(this.state.pets);

    let selectedItems = {
      ...renderables[this.state.selectedIndex].selectedItems,
    }
    selectedItems[item.typeInfo.subCategory] = {
      ...item,
      ...newProps
    }
    this.updateItems(selectedItems);
  }

  updateItems (selectedItems) {
    this.updateSelectedRenderable({
      selectedItems
    })
  }

  onChangeColor(backgroundColor) {
    const characters = this.state.characters.map((character, index) => ({ ...character }));

    this.setState({ backgroundColor, characters })
    localStorage['backgroundColor'] = JSON.stringify(backgroundColor)
  }

  openColorPicker() {
    this.setState({ colorPickerOpen: true })
  }
}

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <RcTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      style={{border: "solid 2px hsl("+value+", 53%, 53%)"}}
      key={index}
    >
      <Handle value={value} {...restProps} />
    </RcTooltip>
  );
};

export default App
