import React, { Component } from 'react'
import './index.css'
import _ from 'lodash'
import Toggle from 'react-toggle'
import { Tooltip } from 'react-tippy'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import 'react-tippy/dist/tippy.css';
import RcTooltip from 'rc-tooltip'
import Slider from 'rc-slider'

const npcLoader = fetch(`https://maplestory.io/api/${localStorage['region']}/${localStorage['version']}/npc`).then(res => res.json())
  .then(npcResp =>
    {
      let uniqNPCs = _.uniqBy(npcResp, npc => npc.name)
      npcs = uniqNPCs.map(npc => {
        return {
          npcId: npc.id,
          name: npc.name
        }
      })
    }) // For some reason the standard [].map doesn't work here. :(

let npcs = []

class NPCEntry extends Component {
  constructor(props) {
    super(props)

    this.state = {};

    if (npcs.length > 0) this.state.npcsLoaded = true;
    else npcLoader.then(() => this.setState({ npcsLoaded: true }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.npc && this.props.npc && prevProps.npc.npcId === this.props.npc.npcId) return
    const { npc: { npcId } } = this.props
  }

  render() {
    const { npc, isSelected, canvasMode, onUpdateNPC, onDeleteNPC, ...otherProps } = this.props
    var resizeIndex = npc.summary.indexOf("?resize=");
    var zoomlessNPCUrl = resizeIndex > 0 ? npc.summary.substring(0, resizeIndex) : npc.summary;
    return (
      <Tooltip html={this.customizeNPC(npc)} delay={[100, 300]} position={canvasMode ? undefined : 'bottom'} interactive={true} theme='light' distance={340} arrow={true}>
        <div
          className={'npc ' + (npc.visible ? 'disabled ' : 'enabled ') + (isSelected ? 'active' : 'inactive')}
          style={{
            backgroundImage: 'url(' + zoomlessNPCUrl + ')',
            transform: npc.flipX ? 'scaleX(-1)' : ''
          }}
          {...otherProps}>&nbsp;</div>
      </Tooltip>
    )
  }

  customizeNPC(npc) {
    const { localized } = this.props
    return (<div className='npc-customizeable-options'>
      <div>
        <a href="#" className='btn bg-red text-white right' onClick={this.deleteNPC.bind(this)}>{localized.deleteNpc}</a>
      </div>
      <label>
        <span>{localized.npcType}</span>
        <select onChange={this.changeNPCId.bind(this)} value={npc.npcId}>
          {
            npcs.map(a => (
              <option value={a.npcId} key={'npcSelect' + a.npcId}>{a.name}</option>
            ))
          }
        </select>
      </label>
      <label>
        <span>{localized.frame}</span>
        <Slider
          value={npc.frame || 0}
          min={0}
          max={10}
          handle={handle}
          onChange={this.changeFrame.bind(this)} />
      </label>
      <label>
        <span>{localized.zoom}</span>
        <Slider
          value={npc.zoom || 1}
          min={1}
          max={10}
          handle={handle}
          onChange={this.changeZoom.bind(this)} />
      </label>
      <label>
        <span>{localized.visible}</span>
        <Toggle onChange={this.toggleVisibility.bind(this)} checked={npc.visible} />
      </label>
      <label>
        <span>{localized.flipHorizontal}</span>
        <Toggle onChange={this.toggleFlipX.bind(this)} checked={npc.flipX} />
      </label>
      <label>
        <span>{localized.footholdSnapping}</span>
        <Toggle onChange={this.toggleFHSnap.bind(this)} checked={npc.fhSnap || false} />
      </label>
      <label>
        <span>{localized.lock}</span>
        <Toggle onChange={this.toggleLock.bind(this)} checked={npc.locked} />
      </label>
      <br />
      <a href={`https://maplestory.io/api/${localStorage['region']}/${localStorage['version']}/npc/${npc.npcId}/download`} target='_blank' rel="noopener noreferrer">
        <div className='download-bar bg-purple'>
          <div className='equipped-items-item-meta'>
            <div className='equipped-items-item-meta-name text-white'>{localized.downloadSpriteSheet}</div>
            <div className='equipped-items-item-meta-category text-white'>({localized.willDownloadZip})</div>
          </div>
        </div>
      </a>
    </div>)
  }

  toggleFHSnap(e) {
    this.props.onUpdateNPC(this.props.npc, { fhSnap: !this.props.npc.fhSnap })
  }

  toggleFlipX(e) {
    this.props.onUpdateNPC(this.props.npc, { flipX: !this.props.npc.flipX })
  }

  deleteNPC() {
    this.props.onDeleteNPC(this.props.npc)
  }

  changeNPCId (e) {
    this.props.onUpdateNPC(this.props.npc, { npcId: e.target.value })
  }

  changeFrame(e) {
    this.props.onUpdateNPC(this.props.npc, { frame: e });
  }

  changeZoom(e) {
    this.props.onUpdateNPC(this.props.npc, { zoom: e });
  }

  toggleVisibility(e) {
    this.props.onUpdateNPC(this.props.npc, { visible: !this.props.npc.visible })
  }

  toggleLock(e) {
    this.props.onUpdateNPC(this.props.npc, { locked: !this.props.npc.locked })
  }
}

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <RcTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      style={{border: "solid 2px hsl("+value+", 53%, 53%)"}}
      key={index}
    >
      <Handle value={value} {...restProps} />
    </RcTooltip>
  );
};

export default NPCEntry
